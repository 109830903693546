import {
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { SlotItem } from 'src/app/components/slot/slot-item';

interface Slots {
  main: SlotItem[];
  top?: SlotItem[];
  subtop?: SlotItem[];
  left?: SlotItem[];
  right?: SlotItem[];
  bottom?: SlotItem[];
}

@Component({
  selector: 'app-stretch-menu',
  templateUrl: './stretch-menu.component.html',
  styleUrls: ['./stretch-menu.component.scss']
})
export class StretchMenuComponent implements OnInit {
  slots: Slots;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe(data => {
      this.slots = data.slots;
    });
  }
}
