import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
import { LanguageService } from 'src/app/backbone/language.service';
import { Subscription, Observable } from 'rxjs';
import { EventBusService } from 'src/app/backbone/event-bus.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnDestroy, ISlotComponent {
  private subsc: Subscription;
  @Input() public data: any = {};
  @Input() public parentForm: FormGroup;

  constructor(
    public language: LanguageService,
    private eventBus: EventBusService
  ) { }

  ngOnInit() {
    this.subsc = this.eventBus.on('actionBarControlReset', (data) => {
      if (
        typeof data.actionParams === 'undefined'
        || typeof data.actionParams.controls === 'undefined'
        || (
          typeof data.actionParams !== 'undefined'
          && typeof data.actionParams.controls !== 'undefined'
          && data.actionParams.controls.indexOf(this.data.actionId) >= 0
        )
      ) {
        this.data.checked = this.data.originalValue;
        if (typeof this.data.change === 'function') {
          const result = this.data.change({
            id: this.data.actionId,
            checked: this.data.checked
          });
          if (result instanceof Observable) {
            result.subscribe();
          }
        }
      }
    });
  }

  change() {
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }
      this.data.changeParams.checked = this.data.checked;
      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  ngOnDestroy(): void {
    this.subsc.unsubscribe();
  }
}
