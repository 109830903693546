<div class="page">
  <app-slot *ngIf="slots.top" [items]="slots.top"></app-slot>
  <app-slot *ngIf="slots.subtop" [items]="slots.subtop" class="shadowed"></app-slot>
  <mat-sidenav-container>
    <mat-sidenav
      *ngIf="slots.left"
      #leftnav
      [(opened)]="opened.left"
      [mode]="mobileQuery.matches ? 'over' : 'push'"
      fixedTopGap="56"
    >
      <app-slot [items]="slots.left"></app-slot>
    </mat-sidenav>
    <mat-sidenav
      *ngIf="slots.right"
      #rightnav
      [(opened)]="opened.right"
      mode="over"
      fixedTopGap="56"
      position="end"
    >
      <app-slot [items]="slots.right"></app-slot>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="content">
        <app-slot [items]="slots.main"></app-slot>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-slot *ngIf="slots.bottom" [items]="slots.bottom"></app-slot>
</div>
