import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { SlotItem } from 'src/app/components/slot/slot-item';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventBusService } from 'src/app/backbone/event-bus.service';
import { take } from 'rxjs/operators';

export interface Slots {
  main: SlotItem[];
  top?: SlotItem[];
  subtop?: SlotItem[];
  left?: SlotItem[];
  right?: SlotItem[];
  bottom?: SlotItem[];
}

@Component({
  selector: 'app-stretch-sidenav',
  templateUrl: './stretch-sidenav.component.html',
  styleUrls: ['./stretch-sidenav.component.scss']
})
export class StretchSidenavComponent implements OnDestroy, OnInit {
  slots: Slots;
  mobileQuery: MediaQueryList;
  subsc: Subscription;

  opened = {
    left: false,
    right: false
  };

  private mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private route: ActivatedRoute,
    private eventBus: EventBusService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 599px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    this.subsc = this.eventBus.on('toggleSidenav', (data) => {
      if (this.opened[data.side]) {
        this.opened[data.side] = false;
      } else {
        this.opened[data.side] = true;
      }
    });
  }

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe(data => {
      this.slots = data.slots;
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
    this.subsc.unsubscribe();
  }
}
