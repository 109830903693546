import { Injectable } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class GetControlByPathService {

  get(control: AbstractControl, path: string | Array<any> = '', getValue = true): any {
    let resultControl: any = control;
    if (path) {
      if (typeof path === 'string') {
        if (typeof resultControl === 'undefined') {
          return undefined;
        }
        resultControl = resultControl.get(path);
      } else {
        for (const item of path) {
          if (item === 'parent') {
            resultControl = resultControl.parent;
          } else {
            if (resultControl instanceof FormArray
              && typeof item === 'string'
            ) {
              const result = [];
              for (const row of resultControl.controls) {
                result.push(row.get(item));
              }
              resultControl = result;
            } else {
              if (typeof resultControl === 'undefined') {
                return undefined;
              }
              resultControl = resultControl.get(item);
            }
          }
        }
      }
    }
    if (getValue) {
      if (Array.isArray(resultControl)) {
        const resultValues = [];
        for (const ctrl of resultControl) {
          resultValues.push(ctrl.value);
        }
        return resultValues;
      } else {
        return resultControl.value;
      }
    } else {
        return resultControl;
    }
  }
}
