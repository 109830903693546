import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopnavComponent } from './topnav.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RoutingService } from 'src/app/backbone/routing.service';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    TopnavComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule
  ]
})
export class TopnavModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('TopnavComponent', TopnavComponent);
  }
 }
