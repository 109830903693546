import { Component, OnInit, Input } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
import { LanguageService } from 'src/app/backbone/language.service';
import { GetArrayPathPipe } from 'src/app/backbone/pipes/get-array-path.pipe';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss'],
  providers: [GetArrayPathPipe]
})
export class ChartPieComponent implements OnInit, ISlotComponent {

  @Input() public data: any = {};

  constructor(
    private language: LanguageService,
    private getArrayPath: GetArrayPathPipe
  ) { }

  ngOnInit(): void {
    if (typeof this.data.bars !== 'undefined') {
      const barsArray = [];

      // tslint:disable-next-line: forin
      for (const name in this.data.bars) {
        const value = this.getArrayPath.transform(this.data.value, this.data.bars[name]);
        barsArray.push({
          name: this.language.getLabel(name),
          value
        });
      }
      this.data.value = barsArray;

      if (this.data.mainBar) {
        const mainBarValue = this.data.value.filter(value => value.name === this.language.getLabel(this.data.mainBar));
        const otherBarValues = this.data.value.filter(value => value.name !== this.language.getLabel(this.data.mainBar));
        for (const object of otherBarValues) {
          mainBarValue[0].value -= object.value;
        }
      }
    }
  }

  onSelect(event: any) {

  }
}



