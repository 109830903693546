import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '../backbone/permissions.service';

interface ExtRoute extends Route {
  requiredPermissions: string[];
}
interface ExtActivatedRouteSnapshot extends ActivatedRouteSnapshot {
  routeConfig: ExtRoute;
}
/**
 * DON'T USE PERMISSION GUARD FOR AUTHENTICATED USERS
 * USE AUTH GUARD INSTEAD
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(
      private router: Router,
      private permissionService: PermissionsService
  ) {}

  canActivate(
    next: ExtActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.permissionService.checkpermissions(next.routeConfig)) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}