<!-- ANCHORS -->
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && data.link && data.type == 'mat-button'"
  [routerLink]="link"
  type="button"
  mat-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && data.link && data.type == 'mat-raised-button'"
  [routerLink]="link"
  type="button"
  mat-raised-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && data.link && data.type == 'mat-stroked-button'"
  [routerLink]="link"
  type="button"
  mat-stroked-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [routerLink]="link"
  type="button"
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && data.link && data.type == 'mat-flat-button'"
  [routerLink]="link"
  type="button"
  mat-flat-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && data.link && data.type == 'mat-icon-button'"
  [routerLink]="link"
  type="button"
  mat-icon-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  (click)="click($event)"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  *ngIf="!data.print && data.link && data.type == 'mat-mini-fab'"
  [routerLink]="link"
  type="button"
  mat-mini-fab
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
</button>

<!-- BUTTONS -->
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && !data.link && data.type == 'mat-button'"
  [type]="data.buttonType"
  mat-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && !data.link && data.type == 'mat-raised-button'"
  [type]="data.buttonType"
  mat-raised-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && !data.link && data.type == 'mat-stroked-button'"
  [type]="data.buttonType"
  mat-stroked-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>

<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && !data.link && data.type == 'mat-flat-button'"
  [type]="data.buttonType"
  mat-flat-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>

<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  (click)="click($event)"
  *ngIf="!data.print && !data.link && data.type == 'mat-icon-button'"
  [type]="data.buttonType"
  mat-icon-button
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  (click)="click($event)"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  *ngIf="!data.print && !parentForm && !data.link && data.type == 'mat-mini-fab'"
  [type]="data.buttonType"
  mat-mini-fab
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
</button>

<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  (click)="click($event)"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  *ngIf="!data.print && parentForm && !data.link && data.type == 'mat-mini-fab'"
  [type]="data.buttonType"
  [formGroup]="parentForm"
  mat-mini-fab
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
</button>

<!-- PRINT BUTTONS -->
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  *ngIf="data.print && !data.link && data.type == 'mat-button'"
  mat-button
  [printSectionId]="data.print"
  ngxPrint
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  *ngIf="data.print && !data.link && data.type == 'mat-raised-button'"
  mat-raised-button
  [printSectionId]="data.print"
  ngxPrint
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  *ngIf="data.print && !data.link && data.type == 'mat-stroked-button'"
  mat-stroked-button
  [printSectionId]="data.print"
  ngxPrint
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  *ngIf="data.print && !data.link && data.type == 'mat-flat-button'"
  mat-flat-button
  [printSectionId]="data.print"
  ngxPrint
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  {{ language.getLabel(data.label) }}
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  *ngIf="data.print && !data.link && data.type == 'mat-icon-button'"
  mat-icon-button
  [printSectionId]="data.print"
  ngxPrint
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
</button>
<button
  [ngClass]="data.class"
  [color]="data.color"
  [disabled]="data.disabled"
  [matBadge]="data.badge ? data.badge.value : null"
  [matBadgePosition]="data.badge ? data.badge.position : ''"
  [matBadgeColor]="data.badge ? data.badge.color : null"
  [matBadgeOverlap]="data.badge ? data.badge.overlap : null"
  [matBadgeSize]="data.badge ? data.badge.size : null"
  [attr.alt]="language.getLabel(data.label)"
  [attr.title]="language.getLabel(data.label)"
  [printSectionId]="data.print"
  ngxPrint
  *ngIf="data.print && !data.link && data.type == 'mat-mini-fab'"
  mat-mini-fab
>
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
</button>
