import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
import { FormGroup, FormControl } from '@angular/forms';
import { LanguageService } from 'src/app/backbone/language.service';
import { EventBusService } from 'src/app/backbone/event-bus.service';
import { Subscription, Observable } from 'rxjs';
import { Event } from 'src/app/backbone/event.class';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnDestroy, ISlotComponent {
  private subsc: Subscription;

  @Input() public data: any = {};
  @Input() public parentForm: FormGroup;
  public suffixAction;
  public suffixIcon;

  constructor(
    public language: LanguageService,
    private eventBus: EventBusService
  ) { }

  ngOnInit() {
    if (typeof this.data.suffixAction !== 'undefined') {
      this.suffixAction = this[this.data.suffixAction];
    }
    if (typeof this.data.suffixIcon !== 'undefined') {
      this.suffixIcon = this.data.suffixIcon;
    }
    this.subsc = this.eventBus.on('actionBarControlReset', (data) => {
      if (
        typeof data.actionParams === 'undefined'
        || typeof data.actionParams.controls === 'undefined'
        || (
          typeof data.actionParams !== 'undefined'
          && typeof data.actionParams.controls !== 'undefined'
          && data.actionParams.controls.indexOf(this.data.actionId) >= 0
        )
      ) {
        this.data.value = this.data.originalValue;
        if (typeof this.data.change === 'function') {
          const result = this.data.change({
            id: this.data.actionId,
            value: this.data.value,
            event: 'change'
          });
          if (result instanceof Observable) {
            result.subscribe();
          }
        }
      }
    });
    if (typeof this.data.maxLength === 'undefined') {
      this.data.maxLength = null;
    }
    if (typeof this.data.originalValue === 'undefined') {
      this.data.originalValue = this.data.value;
    }
  }

  change(event: any) {
    if (typeof this.data.change === 'function') {
      this.data.value = event.target.value;
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }
      this.data.changeParams.value = event.target.value;
      this.data.changeParams.event = 'change';

      if (this.parentForm) {
        this.data.changeParams.control = this.parentForm.get(this.data.name);
      }

      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
  enter() {
    if (typeof this.data.onEnter !== 'undefined') {
      this.eventBus.fire(new Event('actionBarEnterKey', this.data.onEnter));
    }
  }

  togglePassword() {
    const inputTypes = {
      password: 'text',
      text: 'password'
    };
    if (typeof inputTypes[this.data.inputType] !== 'undefined') {
      this.data.inputType = inputTypes[this.data.inputType];
    }

    if (
      typeof this.data.suffixIcon !== 'undefined'
      && typeof this.data.suffixIconToggle !== 'undefined'
    ) {
      switch (this.suffixIcon) {
        case this.data.suffixIcon:
          this.suffixIcon = this.data.suffixIconToggle;
          break;
        case this.data.suffixIconToggle:
          this.suffixIcon = this.data.suffixIcon;
          break;
      }
    }
  }

  ngOnDestroy() {
    if (typeof this.subsc !== 'undefined') {
      this.subsc.unsubscribe();
    }
  }
}
