import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Compiler,
  Injector,
  OnChanges,
  SimpleChanges,
  ComponentFactoryResolver,
  ComponentRef
} from '@angular/core';
import { SlotItem } from './slot-item';
import { SlotDirective } from './slot.directive';
import { ISlotComponent } from './slot-component';
import { NgModuleFactory } from '@angular/core';
import { RoutingService } from 'src/app/backbone/routing.service';
import { PermissionsService } from 'src/app/backbone/permissions.service';
import { FormGroup } from '@angular/forms';
import { CustomComponents } from 'src/app/custom.components';

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
}) 
export class SlotComponent implements OnInit, OnChanges {
  @Input() public items: SlotItem[];
  @Input() public detectChanges: boolean;
  @Input() public componentValue: any;
  @Input() public parentForm: FormGroup;
  @Input() public parentComponent: any;
  @ViewChild(SlotDirective, { static: true }) slotHost: SlotDirective;

  private componentRefs: Array<ComponentRef<unknown>> = [];
  private registry = {
    SidenavComponent: {
      loadChildren: () =>
        import('src/app/components/sidenav/sidenav.module').then(
          m => m.SidenavModule
        )
    },
    TopnavComponent: {
      loadChildren: () =>
        import('src/app/components/topnav/topnav.module').then(m => m.TopnavModule)
    },
    TableListComponent: {
      loadChildren: () =>
        import('src/app/components/lists/table-list/table-list.module')
          .then(m => m.TableListModule)
    },
    GridListComponent: {
      loadChildren: () =>
        import('src/app/components/lists/grid-list/grid-list.module')
          .then(m => m.GridListModule)
    },
    TreeListComponent: {
      loadChildren: () =>
        import('src/app/components/lists/tree-list/tree-list.module')
          .then(m => m.TreeListModule)
    },
    CardListComponent: {
      loadChildren: () =>
        import('src/app/components/lists/card-list/card-list.module')
          .then(m => m.CardListModule)
    },
    FormComponent: {
      loadChildren: () =>
        import('src/app/components/form/form.module')
          .then(m => m.FormModule)
    },
    ActionBarComponent: {
      loadChildren: () =>
        import('src/app/components/action-bar/action-bar.module')
          .then(m => m.ActionBarModule)
    },
    MenuComponent: {
      loadChildren: () =>
        import('src/app/components/menus/menu/menu.module')
          .then(m => m.MenuModule)
    },
    HorizontalMenuComponent: {
      loadChildren: () =>
        import('src/app/components/menus/horizontal-menu/horizontal-menu.module')
          .then(m => m.HorizontalMenuModule)
    },
    TabbedComponent: {
      loadChildren: () =>
        import('src/app/components/tabbed/tabbed.module')
          .then(m => m.TabbedModule)
    },
    CarouselComponent: {
      loadChildren: () =>
        import('src/app/components/carousel/carousel.module')
          .then(m => m.CarouselModule)
    },
    MapComponent: {
      loadChildren: () =>
        import('src/app/components/map/map.module')
          .then(m => m.MapModule)
    },
    AddressAutocomplete: {
      loadChildren: () =>
        import('src/app/components/address-autocomplete/address-autocomplete.module')
          .then(m => m.AddressAutocompleteModule)
    },
    GalleryComponent: {
      loadChildren: () =>
        import('src/app/components/gallery/gallery.module')
          .then(m => m.GalleryModule)
    }
  };
  constructor(
    private compiler: Compiler,
    private injector: Injector,
    private routing: RoutingService,
    private factoryResolver: ComponentFactoryResolver,
    private permissionSevice: PermissionsService
  ) {
    CustomComponents.register(this.registry);
  }

  ngOnInit() {
    if (!this.detectChanges) {
      this.loadComponents();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      typeof changes.componentValue !== 'undefined'
      && typeof changes.componentValue.currentValue !== 'undefined'
    ) {
      // tslint:disable-next-line: forin
      for (const item in this.items) {
        const itemData = { ...this.items[item].data };
        for (const k in itemData) {
          if (itemData[k] === '{value}') {
            itemData[k] = changes.componentValue.currentValue;
            break;
          }
        }
        if (typeof this.componentRefs[+item] !== 'undefined') {
          (this.componentRefs[item].instance as ISlotComponent).data = itemData;
          if (typeof this.componentRefs[item].instance['update'] === 'function') {
            this.componentRefs[item].instance['update']();
          }
        }
      }
    }
    if (this.detectChanges) {
      this.loadComponents();
    }
  }

  async loadComponents() {
    const viewContainerRef = this.slotHost.viewContainerRef;
    viewContainerRef.clear();
    if (this.items) {
      for (const item of this.items) {
        if (!this.permissionSevice.checkpermissions(item)) { continue; }
        if (this.registry[item.component]) {
          await this.registry[item.component].loadChildren()
            .then(elementModuleOrFactory => {
              if (elementModuleOrFactory instanceof NgModuleFactory) {
                // if ViewEngine
                return elementModuleOrFactory;
              } else {
                // if Ivy
                return this.compiler.compileModuleAsync(elementModuleOrFactory);
              }
            })
            .then(moduleFactory => {
              const module = moduleFactory.create(this.injector);
              // TODO Extract in function
              const componentFactory = module.componentFactoryResolver
                .resolveComponentFactory(this.routing.getMappedComponent(item.component));
              const componentRef = viewContainerRef.createComponent(componentFactory);
              if (!item.data) {
                item.data = {};
              }
              item.data.instanceId = (Date.now() + Math.random()) * 10000;

              const itemData = { ...item.data };
              if (typeof this.componentValue !== 'undefined') {
                for (const k in itemData) {
                  if (itemData[k] === '{value}') {
                    itemData[k] = this.componentValue;
                    break;
                  }
                }
              }
              (componentRef.instance as ISlotComponent).data = itemData;
              (componentRef.instance as ISlotComponent).parentForm = this.parentForm;
              (componentRef.instance as ISlotComponent)
                .parentComponent = this.parentComponent;
              this.componentRefs.push(componentRef);
            });
        } else {
          // TODO Extract in function
          const componentFactory = this.factoryResolver
            .resolveComponentFactory(this.routing.getMappedComponent(item.component));
          const componentRef = viewContainerRef.createComponent(componentFactory);
          const itemData = { ...item.data };
          itemData.instanceId = (Date.now() + Math.random()) * 10000;
          if (typeof this.componentValue !== 'undefined') {
            for (const k in itemData) {
              if (itemData[k] === '{value}') {
                itemData[k] = this.componentValue;
                break;
              }
            }
          }
          (componentRef.instance as ISlotComponent).data = itemData;
          (componentRef.instance as ISlotComponent).parentForm = this.parentForm;
          (componentRef.instance as ISlotComponent)
            .parentComponent = this.parentComponent;
          this.componentRefs.push(componentRef);
        }
      }
    }
  }
}
