import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import CustomEditor from 'ckeditor';
import { LanguageService } from 'src/app/backbone/language.service';
import { SlotDialogComponent } from 'src/app/components/slot-dialog/slot-dialog.component';

@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss']
})
export class CkeditorComponent implements OnInit {
  public Editor = CustomEditor;
  public config = {
    toolbar: [
      '|', 'undo', 'redo',
      '|', 'bold', 'italic', 'underline', 'strikethrough', 'link',
      '|', 'outdent', 'indent',
      '|', 'alignment',
      '|', 'fontSize', 'fontColor', 'fontBackgroundColor',
      '|', 'selectAll', 'removeFormat',
      '|', 'numberedList', 'bulletedList', 'horizontalLine',
      '|', 'insertTable', 'imageManager', 'mediaEmbed', 'specialCharacters',
      '|', 'findAndReplace', 'sourceEditing'
    ],
    image: {
      toolbar: [
        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
        '|', 'linkImage', 'toggleImageCaption', 'imageTextAlternative', 'resizeImage'
      ],
      styles: {
        options: [
          { name: 'alignLeft', className: 'float-left' },
          { name: 'alignCenter', className: 'mx-auto' },
          { name: 'alignRight', className: 'float-right' }
        ]
      }
    },
    table: {
      contentToolbar: ['tableRow',
        'tableColumn',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties'
      ]
    }
  };
  @Input() public data: any = {};
  @Input() public parentForm: FormGroup;
  @ViewChild('editor') editorComponent: CKEditorComponent;
  public active = false;
  private dialogRef: MatDialogRef<SlotDialogComponent>;

  constructor(
    public dialog: MatDialog,
    public language: LanguageService,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
  }

  public onReady(editor) {
    editor.on('openImageManager', () => {
      this.zone.run(() => {
        this.data.gallery.data.itemClick = this.insertImage.bind(this);
        const params = {
          width: '80vw',
          data: {
            title: this.data.dialogTitle,
            items: [this.data.gallery],
            close: this.data.dialogClose
          }
        };
        this.dialogRef = this.dialog.open(SlotDialogComponent, params);
      });
    });
  }

  public onFocus() {
    this.active = true;
  }

  public onBlur() {
    this.active = false;
  }

  private getEditor() {
    return this.editorComponent.editorInstance;
  }

  private insertImage(url) {
    this.getEditor().execute('insertImage', {
      source: url
    });
    this.dialogRef.close();
  }
}
