import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from './permissions.service';
import { StateService } from './state.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private state: StateService,
    private permissionService: PermissionsService,
    private api: ApiService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.state.get('auth') === 'true') {
      this.api.getService('UserService').checkSession().subscribe((response) => {
        if (typeof response.result.user.roles.find(
          item => item.key === 'guest'
        ) !== 'undefined') {
          this.state.set('auth', 'false');
          this.router.navigate(['login']);
        }
      });
      if (this.permissionService.checkpermissions(next.routeConfig)) {
        return true;
      } else {
        this.router.navigate(['']);
        return false;
      }
    }
    this.router.navigate(['login']);
    return false;
  }

}
