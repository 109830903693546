import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
import { FormGroup } from '@angular/forms';
import { LanguageService } from 'src/app/backbone/language.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any = {};
  @Input() public parentForm: FormGroup;
  public selected;
  protected onDestroy = new Subject<void>();

  constructor(public language: LanguageService) { }

  ngOnInit() { }

  change(event: any) {
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {
          value: event.value
        };
      } else {
        this.data.changeParams.value = event.value;
      }
      const result = this.data.change(...this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
