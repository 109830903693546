import { Injectable, Injector, ComponentFactoryResolver } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { PermissionsGuard } from './permissions.guard';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  private staticTypeMap = {
    AuthGuard, PermissionsGuard
  };
  private components = {};

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private factoryResolver: ComponentFactoryResolver
  ) { }

  mapComponent(key, component) {
    this.components[key] = component;
  }

  getMappedComponent(key) {
    return this.components[key];
  }

  parseRoute(route) {
    if (route.canActivate) {
      // tslint:disable-next-line: forin
      for (const g in route.canActivate) {
        route.canActivate[g] = this.staticTypeMap[route.canActivate[g]];
      }
    }
    if (route.component) {
      const componentFactory = this.factoryResolver
        .resolveComponentFactory(this.components[route.component]);
      const component = componentFactory.create(this.injector);
      route.component = component.componentType;
    }
  }

  generateRoutes() {
    const router = this.injector.get(Router);
    const factories = {};
    return new Promise<void>((resolve, reject) => {
      this.http.get('./assets/routing-mock.json?v=' + environment.version)
        .subscribe((routing: Array<any>) => {
          // tslint:disable-next-line: forin
          for (const i in routing) {
            const hasOptional = routing[i].path.split('?');
            if (hasOptional.length > 1) {
              // if route path has optional parameters
              // (all after ? are considered optional
              // for example - products/:category/?:filter/:sort)
              // both filter and sort are optionals
              // create routes for all possible cases
              const optionalParams = hasOptional[1].split('/');
              let basePath;
              if (hasOptional[0].endsWith('/')) {
                basePath = hasOptional[0].slice(0, -1);
              } else {
                basePath = hasOptional[0];
              }
              routing[i].path = basePath;
              for (const optional of optionalParams) {
                basePath += '/' + optional;
                const newRoute = JSON.parse(JSON.stringify(routing[i]));
                newRoute.path = basePath;
                this.parseRoute(newRoute);
                routing.push(newRoute);
              }
            }
            this.parseRoute(routing[i]);
          }
          const routes = routing as Routes;
          router.resetConfig(routes);
          resolve();
        });
    });
  }
}
