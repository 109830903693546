import { Component, OnInit, Input } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/backbone/language.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit, ISlotComponent {
  @Input() public data: any = {};
  @Input() public parentForm: FormGroup;

  constructor(public language: LanguageService) { }

  ngOnInit() {
    this.data.checked = Boolean(this.data.checked);
  }

  change(slideToggle) {
    console.log(slideToggle);
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }
      this.data.changeParams.value = slideToggle.checked;
      this.data.changeParams.event = 'change';
      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
}
