import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/backbone/language.service';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, ISlotComponent {
  @Input() public data: any;
  constructor(public language: LanguageService) { }

  ngOnInit(): void {
  }

}
