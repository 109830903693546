import { Injectable, Injector } from '@angular/core';
import { ConfigService } from 'ng-vex-sdk';
import * as sdk from 'ng-vex-sdk';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
      private injector: Injector,
      private config: ConfigService
  ) {
    this.config.url = environment.apiAccessPoint;
    this.config.token = environment.apiToken;
  }

  getService(service) {
    return this.injector.get<any>(sdk[service]);
  }
}
