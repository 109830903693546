<ng-template #contents let-data="data">
  <ng-container *ngIf="data.label">
    {{ language.getLabel(data.label) }}
  </ng-container>
  <ng-container *ngIf="data.value">
    {{ data.value }}
  </ng-container>
</ng-template>

<h1 *ngIf="data.level === 1">
  <ng-container
    *ngTemplateOutlet="contents; context: { data: data }"
  ></ng-container>
</h1>
<h2 *ngIf="data.level === 2">
  <ng-container
    *ngTemplateOutlet="contents; context: { data: data }"
  ></ng-container>
</h2>
<h3 *ngIf="data.level === 3">
  <ng-container
    *ngTemplateOutlet="contents; context: { data: data }"
  ></ng-container>
</h3>
<h4 *ngIf="data.level === 4">
  <ng-container
    *ngTemplateOutlet="contents; context: { data: data }"
  ></ng-container>
</h4>
<h5 *ngIf="data.level === 5">
  <ng-container
    *ngTemplateOutlet="contents; context: { data: data }"
  ></ng-container>
</h5>
<h6 *ngIf="data.level === 6">
  <ng-container
    *ngTemplateOutlet="contents; context: { data: data }"
  ></ng-container>
</h6>
