<mat-form-field appearance="fill" [formGroup]="parentForm" *ngIf="parentForm">
  <mat-chip-list #chipList aria-label="Fruit selection">
    <mat-chip
      *ngFor="let item of items"
      [selectable]="data.chips.selectable"
      [removable]="data.chips.removable"
      (removed)="remove(item)"
    >
      {{ item.text }}
      <mat-icon matChipRemove *ngIf="data.chips.removable">cancel</mat-icon>
    </mat-chip>
    <input
      [id]="data.id ? data.id : null"
      [placeholder]="language.getLabel(data.label)"
      #itemInput
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="change($event)">
    <mat-option *ngFor="let item of filteredItems" [value]="item.value">
      {{ item.text }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field appearance="fill" *ngIf="!parentForm"> </mat-form-field>
