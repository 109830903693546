<mat-form-field
  appearance="fill"
  [class]="data.class"
  [ngClass]="{ readonly: data.readonly }"
  *ngIf="parentForm && data.inputType === 'hidden'"
  [formGroup]="parentForm"
  [hidden]="true"
>
  <ng-container *ngIf="data.type == 'input'">
    <mat-icon *ngIf="data.prefixIcon" matPrefix>{{ data.prefixIcon }}</mat-icon>
    <span *ngIf="data.prefixText" matPrefix>{{ data.prefixText }}</span>
    <input
      matInput
      [id]="data.id"
      [(value)]="data.value"
      (change)="change($event)"
      [placeholder]="language.getLabel(data.placeholder)"
      [formControlName]="data.name"
      [readonly]="data.readonly"
      [step]="data.step"
    />
    <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
    <span *ngIf="data.suffixText" matSuffix>{{ data.suffixText }}</span>
  </ng-container>
</mat-form-field>

<mat-form-field
  appearance="fill"
  [class]="data.class"
  [ngClass]="{ readonly: data.readonly }"
  *ngIf="parentForm && data.inputType !== 'hidden'"
  [formGroup]="parentForm"
>
  <mat-label>{{ language.getLabel(data.label) }}</mat-label>
  <mat-icon *ngIf="data.prefixIcon" matPrefix>{{ data.prefixIcon }}</mat-icon>
  <span *ngIf="data.prefixText" matPrefix>{{ data.prefixText }}</span>
  <ng-container *ngIf="data.type == 'input'">
    <input
      matInput
      [id]="data.id"
      [(value)]="data.value"
      (change)="change($event)"
      [placeholder]="language.getLabel(data.placeholder)"
      [type]="data.inputType"
      [formControlName]="data.name"
      [readonly]="data.readonly"
      [step]="data.step"
      [attr.maxlength]="data.maxLength"
    />
  </ng-container>

  <ng-container *ngIf="data.type == 'textarea'">
    <textarea
      matInput
      [id]="data.id"
      [(value)]="data.value"
      (change)="change($event)"
      [placeholder]="language.getLabel(data.placeholder)"
      [formControlName]="data.name"
      [readonly]="data.readonly"
      [attr.maxlength]="data.maxLength"
    >
    </textarea>
  </ng-container>
  <button
    *ngIf="data.suffixAction"
    type="button"
    matSuffix
    mat-icon-button
    (click)="suffixAction()"
  >
    <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
  </button>
  <mat-icon *ngIf="!data.suffixAction && suffixIcon" matSuffix>
    {{ suffixIcon }}
  </mat-icon>
  <span *ngIf="data.suffixText" matSuffix>{{ data.suffixText }}</span>
</mat-form-field>
<mat-form-field
  appearance="fill"
  [class]="data.class"
  [ngClass]="{ readonly: data.readonly }"
  *ngIf="!parentForm"
>
  <mat-label>{{ language.getLabel(data.label) }}</mat-label>
  <ng-container *ngIf="data.type == 'input'">
    <mat-icon *ngIf="data.prefixIcon" matPrefix>{{ data.prefixIcon }}</mat-icon>
    <span *ngIf="data.prefixText" matPrefix>{{ data.prefixText }}</span>
    <input
      matInput
      [id]="data.id"
      [(value)]="data.value"
      (change)="change($event)"
      [placeholder]="language.getLabel(data.placeholder)"
      [type]="data.inputType"
      [readonly]="data.readonly"
      [step]="data.step"
      (keyup.enter)="enter()"
      [attr.maxlength]="data.maxLength"
    />
    <mat-icon *ngIf="suffixIcon" matSuffix>{{ suffixIcon }}</mat-icon>
    <span *ngIf="data.suffixText" matSuffix>{{ data.suffixText }}</span>
  </ng-container>
  <ng-container *ngIf="data.type == 'textarea'">
    <textarea
      matInput
      [id]="data.id"
      [(value)]="data.value"
      (change)="change($event)"
      [placeholder]="language.getLabel(data.placeholder)"
      [readonly]="data.readonly"
      [attr.maxlength]="data.maxLength"
    >
    </textarea>
  </ng-container>
</mat-form-field>
