<div
  *ngIf="parentForm"
  [formGroup]="parentForm"
  class="display-flex {{data.class}}"
>
<mat-label *ngIf="data.group.label" class="margin-bottom-10-block">
    {{ language.getLabel(data.group.label) }}
  </mat-label>
  <mat-radio-group
    [color]="data.group.color"
    [labelPosition]="data.group.labelPosition"
    [formControlName]="data.group.name"
  >
    <mat-radio-button
      class="example-radio-button"
      *ngFor="let option of data.options"
      [value]="option.option"
      [disabled]="option.disabled"
      [color]="option.color"
      (change)="change($event)"
    >
      {{ language.getLabel(option.name) }}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div *ngIf="!parentForm" class="display-flex">
  <mat-label class="margin-bottom-10-block">
    {{ language.getLabel(data.group.label) }}
  </mat-label>
  <mat-radio-group
    [color]="data.group.color"
    [labelPosition]="data.group.labelPosition"
    [required]="data.group.required"
    [(ngModel)]="data.group.selected"
  >
    <mat-radio-button
      class="example-radio-button"
      *ngFor="let option of data.options"
      [(value)]="option.option"
      [disabled]="option.disabled"
      [color]="option.color"
      (change)="change($event)"
    >
      {{ language.getLabel(option.name) }}
    </mat-radio-button>
  </mat-radio-group>
</div>
