import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import { LanguageService } from 'src/app/backbone/language.service';
import { ISlotComponent } from 'src/app/components/slot/slot-component';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit, ISlotComponent {
  @Input() public data: any = {};
  @Input() public parentForm: FormGroup;
  @ViewChild('picker') public picker: NgxMaterialTimepickerComponent;
  public opened = false;

  constructor(public language: LanguageService) { }

  ngOnInit(): void {
  }
  open() {
    this.opened = true;
  }
  close() {
    this.opened = false;
  }
}
