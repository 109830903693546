import { Component, Input, OnInit } from '@angular/core';
import { GetArrayPathService } from 'src/app/backbone/get-array-path.service';
import { LanguageService } from 'src/app/backbone/language.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() public data: any = {};
  public src: any;
  public onlyOriginal = true;
  public size = '';

  constructor(
    public language: LanguageService,
    private getArrayPath: GetArrayPathService
  ) { }

  ngOnInit(): void {
    if (typeof this.data.displaySize !== 'undefined') {
      this.size = this.data.displaySize + '_';
    }
    if (typeof this.data.srcPath !== 'undefined') {
      // url comes from data object
      this.src = this.getArrayPath.get(this.data.dataObject, this.data.srcPath);
      this.onlyOriginal = (typeof this.src !== 'object');
    } else {
      // direct url configured
      this.src = this.data.src;
    }
  }
}
