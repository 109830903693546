import { Component, OnInit, Input } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { LanguageService } from 'src/app/backbone/language.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, ISlotComponent {
  @Input() public data: any = {};
  @Input() public parentForm: FormGroup;

  constructor(
    public language: LanguageService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale(language.activeLocale);
  }

  ngOnInit() {
    let from;
    if (this.data.value && this.data.value.startsWith('today')) {
      from = new Date();
      this.data.value = from;
    }

    if (this.data.value) {
      if (this.parentForm) {
        this.parentForm.get(this.data.name).setValue(this.data.value);
      }
    }
  }

  change(event: any) {
    if (typeof this.data.change === 'function') {
      const value = new DatePipe('en-GB').transform(event.target.value, 'yyyy-MM-dd');
      if (!this.data.changeParams) {
        this.data.changeParams = {
          value
        };
      } else {
        this.data.changeParams.value = value;
      }
      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
}
