import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from 'src/app/backbone/language.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() public data: any = {};

  constructor(public language: LanguageService) { }

  ngOnInit(): void {
  }

}
