import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, AfterViewInit, AfterContentInit } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
import { FormControl, FormGroup } from '@angular/forms';
import { LanguageService } from 'src/app/backbone/language.service';
import { Subscription, Observable, Subject, ReplaySubject } from 'rxjs';
import { EventBusService } from 'src/app/backbone/event-bus.service';
import { take, takeUntil } from 'rxjs/operators';
import { JsonRpcResponse } from 'ng-vex-sdk';
import { ApiService } from 'src/app/backbone/api.service';
import { GetArrayPathPipe } from 'src/app/backbone/pipes/get-array-path.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [GetArrayPathPipe]
})
export class SelectComponent implements OnInit, OnDestroy, OnChanges, ISlotComponent {
  private subsc: Subscription;
  public value;

  @Input() public data: any;
  @Input() public parentForm: FormGroup;

  public searchOptionsCtrl: FormControl = new FormControl();

  /** Subject that emits when the component has been destroyed. */
  protected onDestroy = new Subject<void>();
  public options = [];
  public filteredOptions: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private api: ApiService,
    public language: LanguageService,
    private eventBus: EventBusService,
    private getArrayPath: GetArrayPathPipe,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this.data.dataSource) {
      let params = {};
      if (typeof this.data.value !== 'undefined') {
        this.value = this.data.value;
      }
      // load options if datasource is provided
      if (typeof this.data.dataSource.params !== 'undefined') {
        params = { ...this.data.dataSource.params };
      }
      this.route.params.pipe(take(1)).subscribe(urlParams => {
        // If params or value has dynamic params from route url - search and replace them
        const stringParams = JSON.stringify(params);
        let replaced = stringParams;
        for (const key of Object.keys(urlParams)) {
          const search = ':' + key;
          replaced = replaced.replace(new RegExp(search, 'g'), urlParams[key]);
          if (typeof this.value === 'string') {
            this.value = this.value.replace(new RegExp(search, 'g'), urlParams[key]);
          }
        }
        if (replaced !== '') {
          params = { ...JSON.parse(replaced) };
        }
      });
      const dataService = this.api.getService(this.data.dataSource.service);
      dataService[this.data.dataSource.method](params)
        .pipe(take(1))
        .subscribe((response: JsonRpcResponse) => {
          this.data.options = [];
          response.result.data.forEach((item) => {
            const value = this.getArrayPath.transform(
              item,
              this.data.dataSource.valuePath
            );
            let label: string;
            if (this.data.dataSource.labelPath) {
              label = this.getArrayPath.transform(
                item,
                this.data.dataSource.labelPath
              );
            }
            if (this.data.dataSource.multiLabelPath) {
              const labelParts = [];
              for (const path of this.data.dataSource.multiLabelPath) {
                labelParts.push(this.getArrayPath.transform(item, path));
              }
              label = labelParts.join(' ');
            }
            this.data.options.push({
              value,
              text: label
            });
            if (this.value) {
              this.data.selected = this.value;
            }
          });
          this.options = this.data.options;
          this.filteredOptions.next(this.data.options.slice());
        });
    } else {
      if (!this.data.options && this.data.optionsPath) {
        let options = this.getArrayPath.transform(undefined, this.data.optionsPath);
        if (typeof options === 'string') {
          options = options.split(',').map(value => {
            return { text: value, value };
          });
        }
        this.data.options = options;
      }
      this.options = this.data.options;
      this.filteredOptions.next(this.data.options.slice());
    }
    if (this.data.searchable) {
      if (this.parentForm) {
        this.searchOptionsCtrl.valueChanges
          .pipe(takeUntil(this.onDestroy))
          .subscribe(() => {
            if (!this.options) {
              return;
            }

            let search = this.searchOptionsCtrl.value;
            if (!search) {
              this.filteredOptions.next(this.options.slice());
            } else {
              search = search.toLowerCase();
            }

            this.filteredOptions.next(
              this.options.filter(option => {
                if (typeof option.text === 'string') {
                  return option.text.toLowerCase().indexOf(search) > -1;
                } else {
                  return undefined;
                }
              })
            );
          });
      }
    }
    this.subsc = this.eventBus.on('actionBarControlReset', (data) => {
      if (
        typeof data.actionParams === 'undefined'
        || typeof data.actionParams.controls === 'undefined'
        || (
          typeof data.actionParams !== 'undefined'
          && typeof data.actionParams.controls !== 'undefined'
          && data.actionParams.controls.indexOf(this.data.actionId) >= 0
        )
      ) {
        this.data.selected = this.data.originalSelected;
        if (typeof this.data.change === 'function') {
          const result = this.data.change({
            id: this.data.actionId,
            value: this.data.selected
          });
          if (result instanceof Observable) {
            result.subscribe();
          }
        }
      }
    });
    if (typeof this.data.originalSelected === 'undefined') {
      this.data.originalSelected = this.data.selected;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  change(event: any, options: any) {
    if (typeof this.data.emptyOption !== 'undefined' && event.value === '') {
      return;
    }
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }
      this.data.changeParams.event = 'change';
      this.data.changeParams.value = event.value;
      this.data.changeParams.options = options;

      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }

  ngOnDestroy() {
    if (this.subsc) {
      this.subsc.unsubscribe();
    }
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
