import { Component, Input } from '@angular/core';
import { UserService } from 'ng-vex-sdk';
import { Router } from '@angular/router';
import { StateService } from 'src/app/backbone/state.service';
import { take } from 'rxjs/operators';
import { EventBusService } from 'src/app/backbone/event-bus.service';
import { Event } from 'src/app/backbone/event.class';
import { LanguageService } from 'src/app/backbone/language.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent {
  @Input() data: any;

  constructor(
    private user: UserService,
    public state: StateService,
    private router: Router,
    private eventBus: EventBusService,
    public language: LanguageService
  ) { }

  logout(): void {
    this.user.logout()
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.state.set('auth', 'false');
          this.router.navigate(['login']);
        },
        (err) => {
          this.state.set('auth', 'false');
          this.router.navigate(['login']);
        });
  }

  toggle(side: string) {
    this.eventBus.fire(new Event('toggleSidenav', { side }));
  }
}
