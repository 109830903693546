import { Component, Input, OnInit } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';

@Component({
  selector: 'app-horizontal-line',
  templateUrl: './horizontal-line.component.html',
  styleUrls: ['./horizontal-line.component.scss']
})
export class HorizontalLineComponent implements OnInit, ISlotComponent {
  @Input() public data: any;
  constructor() { }

  ngOnInit(): void {
  }

}
