import { Component, Input, OnInit } from '@angular/core';
import { JsonRpcResponse } from 'ng-vex-sdk';
import { ApiService } from 'src/app/backbone/api.service';
import { FileService } from 'src/app/backbone/executables';
import { ISlotComponent } from 'src/app/components/slot/slot-component';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit, ISlotComponent {
  @Input() public data: any = {};

  public filename;

  constructor(
    private api: ApiService,
    private file: FileService
  ) { }

  ngOnInit(): void {
    this.filename = this.data.file.filename;
  }
  download() {
    const parsed = this.data.file.url_params.method.split('.');
    const method = parsed[2];
    const serviceName = parsed[1].charAt(0).toUpperCase() +
      parsed[1].slice(1) + 'Service';
    const service = this.api.getService(serviceName);
    service[method](this.data.file.url_params.params)
      .subscribe((data: JsonRpcResponse) => {
        this.file.download(data);
      });
  }
}
