import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../language.service';
import { PermissionsService } from '../permissions.service';
import { SessionService } from '../session.service';
import { StateService } from '../state.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private state: StateService,
    private session: SessionService,
    private language: LanguageService,
    private permissionSevice: PermissionsService,
    private router: Router
  ) { }

  login(response) {
    this.state.set('auth', 'true');
    this.language.setLanguage(response.result.locale);
    this.permissionSevice.setSession(response.result);

    let lastUrl = this.session.get('lastUrl');
    if (lastUrl) {
      lastUrl = JSON.parse(lastUrl);
      if (
        [
          '/login',
          '/init-password-reset',
          '/reset-password'
        ].some(path => lastUrl[0].startsWith(path))
      ) {
        this.router.navigate(['']);
      } else {
        this.router.navigateByUrl(decodeURI(lastUrl[0]));
      }
    } else {
      this.router.navigate(['']);
    }
  }
}
