import { Injectable, Injector } from '@angular/core';
import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
    FormArray,
} from '@angular/forms';
import { FormArrayValidationFilter } from './interfaces/form-array-validation-filter.interface';

@Injectable()
export class CustomValidators {
    static match(matchTo: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.parent && control.parent.value
                && control.value === control.parent.value[matchTo]) {
                return null;
            } else {
                return { match: control.value };
            }
        };
    }

    static uniqueInArray(element: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (typeof control.parent !== 'undefined' && control.parent !== null) {
                const group = control.parent;
                if (group.parent) {
                    const formArrayObject = group.parent as FormArray;
                    for (const arrayGroup of formArrayObject.controls) {
                        if (
                            arrayGroup !== group
                            && arrayGroup['controls'][element].value === value
                        ) {
                            return {
                                uniqueInArray: control.value
                            };
                        }
                    }
                }
            }
            return null;
        };
    }

    static greaterThan(value: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.parent && control.parent.value
                && control.value > value) {
                return null;
            } else {
                return { greaterThan: value };
            }
        }
    }

    static formArraySumLtEq(
        controlName: string,
        limit: number,
        filter: FormArrayValidationFilter | null = null
    ): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (typeof control.parent !== 'undefined') {
                const formArray = control.parent.parent;
                if (formArray instanceof FormArray) {
                    let sum = 0;
                    const rows = formArray.controls;
                    for (const row of rows) {
                        const filterValue = row.get(filter.controlName).value;
                        if (
                            filter.controlValues.indexOf(filterValue) >= 0
                            && row['groupUid'] === control.parent['groupUid']
                        ) {
                            sum += parseInt(row.get(controlName).value, 10);
                        }
                    }
                    if (sum > limit) {
                        return {
                            formArraySumLtEq: limit
                        };
                    } else {
                        for (const row of rows) {
                            const filterValue = row.get(filter.controlName).value;
                            if (
                                filter.controlValues.indexOf(filterValue) >= 0
                                && row['groupUid'] === control.parent['groupUid']
                            ) {
                                row.get(controlName).setErrors(null);
                            }
                        }
                    }
                }
            }
            return null;
        };
    }
    static formArraySumGtEq(
        controlName: string,
        limit: number,
        filter: FormArrayValidationFilter | null = null
    ): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (typeof control.parent !== 'undefined') {
                const formArray = control.parent.parent;
                if (formArray instanceof FormArray) {
                    let sum = 0;
                    const rows = formArray.controls;
                    for (const row of rows) {
                        const filterValue = row.get(filter.controlName).value;
                        if (
                            filter.controlValues.indexOf(filterValue) >= 0
                            && row['groupUid'] === control.parent['groupUid']
                        ) {
                            sum += parseInt(row.get(controlName).value, 10);
                        }
                    }
                    if (sum < limit) {
                        return {
                            formArraySumGtEq: limit
                        };
                    } else {
                        for (const row of rows) {
                            const filterValue = row.get(filter.controlName).value;
                            if (
                                filter.controlValues.indexOf(filterValue) >= 0
                                && row['groupUid'] === control.parent['groupUid']
                            ) {
                                row.get(controlName).setErrors(null);
                            }
                        }
                    }
                }
            }
            return null;
        };
    }
    static formArraySumEq(
        controlName: string,
        limit: number,
        filter: FormArrayValidationFilter | null = null,
        deviation: number | null
    ): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (typeof control.parent !== 'undefined') {
                const formArray = control.parent.parent;
                if (formArray instanceof FormArray) {
                    let sum = 0;
                    const rows = formArray.controls;
                    for (const row of rows) {
                        const filterValue = row.get(filter.controlName).value;
                        if (
                            filter.controlValues.indexOf(filterValue) >= 0
                            && row['groupUid'] === control.parent['groupUid']
                        ) {
                            sum += parseInt(row.get(controlName).value, 10);
                        }
                    }
                    let valid = false;;
                    if (deviation !== null) {
                        if (sum >= (limit - deviation) && sum <= (limit + deviation)) {
                            valid = true;
                        }
                    } else {
                        if (sum === limit) {
                            valid = true;
                        }
                    }
                    for (const row of rows) {
                        const filterValue = row.get(filter.controlName).value;
                        if (
                            filter.controlValues.indexOf(filterValue) >= 0
                            && row['groupUid'] === control.parent['groupUid']
                        ) {
                            row.get(controlName).setErrors(null);
                        }
                    }
                    if (!valid) {
                        return {
                            formArraySumEq: {
                                limit,
                                sum
                            }
                        };
                    }
                }
            }
            return null;
        };
    }
}
