import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  download(data) {
    const blob = this.b64toBlob(data.result.content, data.result.mimetype);

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, data.result.filename);
    } else {
      const link = this.document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', data.result.filename);
        link.style.visibility = 'hidden';
        this.document.body.appendChild(link);
        link.click();
        this.document.body.removeChild(link);
      }
    }
  }
  private b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
