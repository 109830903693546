<mat-form-field appearance="fill" [formGroup]="parentForm" *ngIf="parentForm">
  <input
    matInput
    [id]="data.id ? data.id : null"
    [placeholder]="language.getLabel(data.label)"
    [formControlName]="data.name"
    [type]="data.inputType"
    [matAutocomplete]="auto"
  />
  <mat-icon *ngIf="data.suffixIcon" matSuffix>{{ data.suffixIcon }}</mat-icon>

  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="
      data.autoActiveFirstOption ? data.autoActiveFirstOption : false
    "
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="change($event)"
  >
    <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
      {{ option.text }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field appearance="fill" *ngIf="!parentForm">
  <input
    matInput
    [id]="data.id ? data.id : null"
    [placeholder]="language.getLabel(data.label)"
    [formControl]="formControl"
    [type]="data.inputType"
    [matAutocomplete]="auto"
  />
  <mat-icon *ngIf="data.suffixIcon" matSuffix>{{ data.suffixIcon }}</mat-icon>

  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="
      data.autoActiveFirstOption ? data.autoActiveFirstOption : false
    "
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="change($event)"
  >
    <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
      {{ option.text }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
