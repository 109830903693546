export const environment = {
  production: true,
  version: '1.2.0',
  light_logo: 'bulgariaair_logo_light.png',
  dark_logo: 'bulgariaair_logo.png',
  title: 'Bulgaria Air',
  apiAccessPoint: {
    protocol: 'https',
    hostname: 'mportal.air.bg:9999',
    path: 'api'
  },
  // tslint:disable-next-line: max-line-length
  apiToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiNGI5ZDlhN2RmZmJkMmVmOGY4NzA5MTgzMzFiZjE4YWIxMzJhYTgyNjlhYzU2NmY2NzE1OWU3NzliY2Y1ZjI3NWNjNTNlMjI3MDg3N2UzMDUiLCJpYXQiOjE2MTk2ODI0OTAsIm5iZiI6MTYxOTY4MjQ5MCwiZXhwIjoxNjUxMjE4NDkwLCJzdWIiOiIiLCJzY29wZXMiOltdfQ.JZolXdmCkC0KaS4clMdD63x1HFPKSRJsdDOx_ndOCrSgOOqtGxeuIRHlPy5Ajn6265wDbODeETp2OSo5sW4R9TeRsSKEi-XaXJqctA7hviS93ljCVBsuixkNPNlVR2MS_Cu5cWP4FxCTqynx_qI8Lt_o3PcfeV0WzPsEiWJ8JtOIVmUGIA5jR_mhVZfiSYTrMpOlERDPKwuaJ1RGssZKKXxdWdWjl__qyt72gU_2vqq5gt7rhuJGJJc7qkrkQelihbszzx0vO9QmcKsg8wTb7wz4icGp0l0j1tqlET4mA6Xap_MPgJj3zKD015xqKufq5arynfACEGAaoS1OrvyWBEge-fqpiIuo9b1ZssqIQYIOmYoC6K_R7_Mxj3tPYvUVtp3qt1IMv5UE8fEC-mu6hsn1Sp8zqMse4mY9qkPOgktFJCesHcclcs1iHagqYrzA3CfU03mCS3u158K1ngxZsxlAZrmSABeujg4-zMDitmqc2Rp-L4oZ-Mzkx-GAYjapDcXxmUK9IF-szBWarbZmn7MpNVSKFyDzk_21a0paZyJCYci0NDpYUZJanZyvfly64gFdIxY1SCgiex7Q27ic54FPVG0SoSotzSN10hbftq0LOnoq-3QJjgaG8R5EhD6jzW3p9Ab7zy1OodTcwraIORfr63KTs_fKxq_KDyS3qmo',
  mapApiToken: null
};
