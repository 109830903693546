import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
import { LanguageService } from 'src/app/backbone/language.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges, ISlotComponent {
  @Input() public data: any;
  @Input() disabled: any;
  @Input() public parentForm: FormGroup;
  public link;

  constructor(
    public language: LanguageService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    if (typeof this.data.buttonType === 'undefined') {
      this.data.buttonType = 'button';
    }
    if (this.data.link) {
      this.link = this.data.link;
      this.activatedRoute.params.subscribe(urlParams => {
        let replaced = '';
        for (const key of Object.keys(urlParams)) {
          const search = ':' + key;
          replaced = this.data.link.replace(new RegExp(search, 'g'), urlParams[key]);
          this.link = replaced;
        }
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.disabled
      && changes.disabled.currentValue !== changes.disabled.previousValue
    ) {
      this.data.disabled = this.disabled;
    }
  }

  click($event) {
    if (typeof this.data.click === 'function') {
      $event.preventDefault();
      if (!this.data.clickParams) {
        this.data.clickParams = {};
        this.data.clickParams.event = 'click';
        if (this.data.dataObject) {
          this.data.clickParams.dataObject = this.data.dataObject;
        }
      }
      if (this.parentForm) {
        if (this.data.name) {
          this.data.clickParams.control = this.parentForm.get(this.data.name);
          if (this.data.clickParams.control.disabled) {
            return;
          }
        }
      }
      const result = this.data.click(this.data.clickParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
}
