import { Injectable, InjectionToken, Inject } from '@angular/core';

export const SESSION_STORAGE = new InjectionToken<Storage>('Session Storage', {
  providedIn: 'root',
  factory: () => sessionStorage
});

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  globalSession: {[k: string]: any} = {};

  constructor(
    @Inject(SESSION_STORAGE) private storage: Storage
  ) { }
  get(key: string): string {
    return this.storage.getItem(key);
  }
  set(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  setGlobalVar(key, value) {
    this.globalSession[key] = value;
  }
  getGlobalVar(key) {
    return this.globalSession[key];
  }
}
