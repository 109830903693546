import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { StateService } from '../state.service';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { LoaderService } from '../loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private activeRequests: number;
    constructor(
        private loader: LoaderService,
        private state: StateService
    ) {
        this.activeRequests = +this.state.get('activeRequest');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.activeRequests++;
        this.state.set('activeRequests', this.activeRequests.toString());
        if (this.activeRequests === 1) {
            this.loader.show();
        }
        return next.handle(req).pipe(
            finalize(() => {
                this.activeRequests--;
                this.state.set('activeRequests', this.activeRequests.toString());
                if (this.activeRequests === 0) {
                    this.loader.hide();
                }
            })
        );
    }
}
