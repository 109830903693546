import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CloneAbstractControlService {

  public clone<T extends AbstractControl>(control: T): T {
    let newControl: T;
    if (control instanceof FormGroup) {
      const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
      const controls = control.controls;
      Object.keys(controls).forEach(key => {
        formGroup.addControl(key, this.clone(controls[key]));
      });
      newControl = formGroup as any;
    } else if (control instanceof FormArray) {
      const formArray = new FormArray([], control.validator, control.asyncValidator);
      control.controls.forEach(formControl => formArray.push(this.clone(formControl)));
      newControl = formArray as any;
    } else if (control instanceof FormControl) {
      newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
      if (typeof control['def'] !== 'undefined') {
        newControl['def'] = control['def'];
      }
    } else {
      throw new Error('Error: unexpected control value');
    }
    if (control.disabled) { newControl.disable({ emitEvent: false }); }
    return newControl;
  }
}
