<a
  *ngIf="!external"
  [class]="data.class"
  [routerLink]="data.url"
  [attr.target]="data.target"
>
  {{ data.text }}
  <app-slot *ngIf="data.components" [items]="data.components"></app-slot>
</a>
<a
  *ngIf="external"
  [class]="data.class"
  [href]="data.url"
  [attr.target]="data.target"
>
  {{ data.text }}
  <app-slot *ngIf="data.components" [items]="data.components"></app-slot>
</a>
