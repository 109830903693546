import { Injectable } from '@angular/core';
import { StateService } from '../backbone/state.service';
import { ApiService } from 'src/app/backbone/api.service';
import { JsonRpcResponse } from 'ng-vex-sdk/public-api';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  labels = {};
  public activeLocale;

  constructor(
    private api: ApiService,
    private state: StateService,
  ) { }

  public setLanguage(locale?: string) {
    let activeLanguage = this.state.get('language');
    if (typeof locale !== 'undefined') {
      activeLanguage = locale;
    }

    if (activeLanguage === null) {
      this.state.set('language', this.state.get('languages.default'));
      activeLanguage = this.state.get('languages.default');
    } else {
      this.state.set('language', activeLanguage);
    }
    this.getLabels({ locale: activeLanguage });
    this.activeLocale = activeLanguage;
  }

  public getLanguages() {
    return new Promise<void>((resolve, reject) => {
      const localeService = this.api.getService('LocaleService');
      localeService.getLocales()
        .subscribe((response: JsonRpcResponse) => {
          this.state.set('languages', response.result.all);
          this.state.set('languages.active', response.result.active);
          this.state.set('languages.required', response.result.required);
          this.state.set('languages.default', response.result.default);
          this.state.set(
            'languages.full_locale_map',
            JSON.stringify(response.result.full_locale_map).replace('_','-')
          );
          resolve();
        });
    });
  }

  public getFullLocale() {
    const map = JSON.parse(this.state.get('languages.full_locale_map'));
    return map[this.activeLocale];
  }

  public getLabels(params?: any) {
    const labelService = this.api.getService('LabelService');
    labelService.getPublished(params)
      .subscribe((response: JsonRpcResponse) => {
        this.labels = response.result;
      });
  }

  private flatten(obj: {}) {
    const flat = {};
    for (const i in obj) {
      if (obj.hasOwnProperty(i)) {
        if (typeof obj[i] === 'object' && obj[i] !== null) {
          const flattened = this.flatten(obj[i]);
          for (const ii in flattened) {
            if (flattened.hasOwnProperty(ii)) {
              flat[i + '.' + ii] = flattened[ii];
            }
          }
        } else {
          flat[i] = obj[i];
        }
      }
    }
    return flat;
  }

  public getLabel(key: string, params?: { [key: string]: any }) {
    if (typeof this.labels[key] === 'undefined') {
      return key;
    }
    let text = this.labels[key];
    if (typeof params !== 'undefined') {
      params = this.flatten(params);
      const placeholders = text.match('{.+?}');
      if (Array.isArray(placeholders)) {
        for (const p of placeholders) {
          if (p.search('%') >= 0) {
            // if placeholder is a sub label that varies by a param
            // parse it and extract is text
            let subLabel = p;
            for (const index in params) {
              if (subLabel.search('%') < 0) {
                break;
              } else {
                subLabel = subLabel.replace('%' + index + '%', params[index])
                  .replace('{', '')
                  .replace('}', '');
              }
            }
            // replace placeholder with the sub label text
            const subLabelText = this.getLabel(subLabel, params);
            text = text.replace(p, subLabelText);
          }
        }
      }
      for (const index in params) {
        if (text.search('{') < 0) {
          break;
        } else {
          text = text.replace('{' + index + '}', params[index]);
        }
      }
    }
    return text;
  }
}
