import { Injectable } from '@angular/core';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class GetArrayPathService {
  constructor(private state: StateService) { }

  get(input: Array<any>, path: string | Array<any> = ''): any {
    if (
      (typeof input === 'undefined' || input === null)
      && Array.isArray(path)
    ) {
      const storage = this[path[0]];
      const clonedPath = [...path];
      clonedPath.shift();
      const location = clonedPath.join('.');
      return storage.get(location);
    }
    if (path) {
      if (typeof path === 'string') {
        if (typeof input === 'undefined' || input === null) {
          return undefined;
        }
        input = input[path];
      } else {
        for (const item of path) {
          if (input instanceof Array
            && typeof item === 'string'
          ) {
            if (input.length === 0) {
              return undefined;
            }
            const result = [];
            input.forEach((arrayItem) => {
              result.push(arrayItem[item]);
            });
            input = result;
          } else {
            if (typeof input === 'undefined') {
              return undefined;
            }
            if (input != null) {
              input = input[item];
            }
          }
        }
      }
    }
    return input;
  }
}
