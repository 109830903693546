import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { JsonRpcError } from 'ng-vex-sdk';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from '../components/toast/toast.component';
import { NavigationEnd, Router } from '@angular/router';
import { StateService } from './state.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {
  private router;

  constructor(
    private alert: MatSnackBar,
    private zone: NgZone,
    private injector: Injector,
    private state: StateService
  ) { }

  private getRouter() {
    this.router = this.injector.get(Router);
  }

  private showGeneralError(errors: any[]) {
    let messages: Array<string> = [];
    for (const i in errors) {
      if (errors[i] instanceof Array) {
        if (errors[i].length > 1) {
          for (const ii of errors[i]) {
            messages.push(ii);
          }
        } else {
          messages.push(errors[i]);
        }
      } else {
        messages = errors;
      }
    }
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'right';
    config.verticalPosition = 'top';
    config.panelClass = ['alert', 'alert-danger'];
    config.data = {
      messages
    };
    this.zone.run(() => {
      const toastRef = this.alert.openFromComponent(ToastComponent, config)
      this.router.events.pipe(first(event => event instanceof NavigationEnd))
        .subscribe((event) => {
          toastRef.dismiss();
        });
    });
  }
  handleError(error: any) {
    this.getRouter();
    let messages: Array<string> = [];
    if (error instanceof Array) {
      for (const e of error) {
        if (e instanceof JsonRpcError) {
          if (e.code === -32401) {
            this.state.set('auth', 'false');
            this.router.navigate(['login']);
          }
          const errors = Object.values(e.data);
          messages.push(...errors);
        }
      }
    } else if (error.name === 'JsonRpcError') {
      if (error.code === -32401) {
        this.state.set('auth', 'false');
        this.router.navigate(['login']);
      }
      if (typeof error.data === 'string') {
        messages.push(error.data);
      } else {
        messages = Object.values(error.data);
      }
    } else if (typeof this[error.status] !== 'undefined') {
      this[error.status](error);
    } else {
      console.error(error);
    }

    if (messages.length > 0) {
      this.showGeneralError(messages);
    }
  }

  private 401(error) {
    this.state.set('auth', 'false');
    this.router.navigate(['login']);
  }
}
