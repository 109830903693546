import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlankComponent } from './blank/blank.component';
import { CenterScreenComponent } from './center-screen/center-screen.component';
import { StretchMenuComponent } from './stretch-menu/stretch-menu.component';
import { StretchSidenavComponent } from './stretch-sidenav/stretch-sidenav.component';
import { RoutingService } from 'src/app/backbone/routing.service';
import { SlotModule } from '../slot/slot.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TopnavModule } from '../topnav/topnav.module';
import { SidenavModule } from '../sidenav/sidenav.module';
import { CenteredHeaderMainFooterComponent } from './centered-header-main-footer/centered-header-main-footer.component';



@NgModule({
  declarations: [
    BlankComponent,
    CenterScreenComponent,
    StretchMenuComponent,
    StretchSidenavComponent,
    CenteredHeaderMainFooterComponent,
    CenteredHeaderMainFooterComponent
  ],
  imports: [
    CommonModule,
    SlotModule,
    AppRoutingModule,
    MatIconModule,
    MatSidenavModule,
    TopnavModule,
    SidenavModule
  ],
  exports: [
    BlankComponent,
    CenterScreenComponent,
    StretchMenuComponent,
    StretchSidenavComponent,
    CenteredHeaderMainFooterComponent
  ]
})
export class LayoutsModule {
  constructor(routing: RoutingService) {
    routing.mapComponent('BlankComponent', BlankComponent);
    routing.mapComponent('CenterScreenComponent', CenterScreenComponent);
    routing.mapComponent('StretchMenuComponent', StretchMenuComponent);
    routing.mapComponent('StretchSidenavComponent', StretchSidenavComponent);
    routing.mapComponent('CenteredHeaderMainFooterComponent', CenteredHeaderMainFooterComponent);
  }
}
