import { Component, OnInit } from '@angular/core';
import { SlotItem } from 'src/app/components/slot/slot-item';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

export interface Slots {
  main: SlotItem[];
}

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {
  slots: Slots;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(data => {
      this.slots = data.slots;
    });
  }

}
