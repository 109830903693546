import { Injectable } from '@angular/core';
import { Condition } from './interfaces/condition.interface';
import { GetArrayPathPipe } from './pipes/get-array-path.pipe';

@Injectable({
  providedIn: 'root'
})
export class EvalService {

  private fn = {
    '===': (a, b) => a === b,
    '!==': (a, b) => a !== b,
    '<': (a, b) => a < b,
    '>': (a, b) => a > b,
    '<=': (a, b) => a <= b,
    '>=': (a, b) => a >= b,
    typeof: (a, b) => typeof a === b,
    in: (a, b) => b.indexOf(a) >= 0
  };

  constructor(private getArrayPath: GetArrayPathPipe) { }

  exec(data, condition: Condition): boolean {
    if (typeof condition.any === 'undefined') {
      condition.any = false;
    }
    const a = this.getArrayPath.transform(data, condition.path);
    let b;
    if (Array.isArray(condition.value) && condition.op !== 'in') {
      b = this.getArrayPath.transform(data, condition.value);
    } else {
      if (condition.value === 'null') {
        b = null;
      } else {
        b = condition.value;
      }
    }

    let result: boolean;
    if (Array.isArray(a)) {
      const stack = [];
      for (const item of a) {
        stack.push(this.fn[condition.op](item, b));
      }
      if (condition.any) {
        result = false;
        if (stack.indexOf(true) >= 0) {
          result = true;
        }
      } else {
        result = true;
        if (stack.indexOf(false) >= 0) {
          result = false;
        }
      }
    } else {
      result = this.fn[condition.op](a, b);
    }

    return result;
  }
}
