import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/backbone/api.service';
import { last, switchMap, take, takeUntil } from 'rxjs/operators';
import { GetArrayPathPipe } from 'src/app/backbone/pipes/get-array-path.pipe';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SlotDialogComponent } from 'src/app/components/slot-dialog/slot-dialog.component';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { JsonRpcResponse } from 'ng-vex-sdk';
import { SessionService } from 'src/app/backbone/session.service';
import { CloneAbstractControlService } from 'src/app/backbone/clone-abstract-control.service';
import { GetControlByPathService } from 'src/app/backbone/get-control-by-path.service';
import { concat, Observable, of, Subject } from 'rxjs';
import { CustomValidators } from 'src/app/backbone/validators';
import { ValidationRule } from 'src/app/backbone/interfaces/validation-rule.interface';
import { FormArrayValidationFilter } from 'src/app/backbone/interfaces/form-array-validation-filter.interface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EvalService } from 'src/app/backbone/eval.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from 'src/app/components/toast/toast.component';
import { LanguageService } from 'src/app/backbone/language.service';

// TODO remove this onces angular adds method addValidators()
declare module '@angular/forms' {
  interface AbstractControl {
    addValidators(validators: ValidatorFn[]): void;
  }
}
AbstractControl.prototype.addValidators = function (
  this: AbstractControl,
  validators: ValidatorFn[]
) {
  if (!validators || !validators.length) {
    return;
  }
  this.setValidators(this.validator ? [this.validator, ...validators] : validators);
};

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  providers: [GetArrayPathPipe]
})
export class ActionComponent implements OnInit, OnDestroy {
  @Input() public data: any;
  @Input() public parentForm: FormGroup;
  @Input() public parentComponent: any;
  @Input() public triggerChange: number;

  private rowTemplate;
  private sizeObserver;
  private destroyed = new Subject<void>();

  alertDialog: MatDialogRef<any>;
  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private alert: MatSnackBar,
    private cloner: CloneAbstractControlService,
    private getArrayPath: GetArrayPathPipe,
    private getControlByPath: GetControlByPathService,
    private session: SessionService,
    private breakpointObserver: BreakpointObserver,
    private evaluator: EvalService,
    private language: LanguageService
  ) {
    this.sizeObserver = this.breakpointObserver.observe(
      [Breakpoints.XSmall, Breakpoints.Small]
    );
  }

  ngOnInit() {
    // tslint:disable-next-line: forin
    for (const event in this.data.events) {
      if (this.data.control) {
        this.data.control.data[event] = this.doAction.bind(this);
      }
    }
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.triggerChange.currentValue && this.data.control.dataObject === '{value}') {
  //     this.data.control.dataObject = this.getArrayPath.transform(
  //       this.data.dataObject,
  //       this.data.path
  //     );
  //   }
  // }

  doAction(state): void | Observable<unknown> {
    const observables = [];
    // tslint:disable-next-line: forin
    for (const action in this.data.events[state.event]) {
      observables.push(this[action](
        state,
        this.data.events[state.event][action],
        this.parentForm
      ));
    }
    if (observables.length > 0) {
      const lastAction = this.data.events[state.event][
        Object.keys(this.data.events[state.event])[observables.length - 1]
      ];
      if (
        typeof lastAction.subscribable !== 'undefined'
        && lastAction.subscribable
      ) {
        return concat(...observables).pipe(last());
      } else {
        concat(...observables).subscribe(() => { });
      }
    }
  }

  private prepCallParamsRecursive(params, state) {
    for (const param in params) {
      if (typeof params[param] === 'object') {
        if (Array.isArray(params[param])) {
          params[param] = this.getArrayPath.transform(this.data.dataObject, params[param]);
        } else {
          params[param] = this.prepCallParamsRecursive(params[param], state);
        }
      } else if (params[param] === '{value}') {
        params[param] = state.value;
      }
    }

    return params;
  }
  private call(state, action) {
    const service = this.api.getService(action.service);
    const params = { ...action.params };
    this.prepCallParamsRecursive(params, state);

    return service[action.method](params)
      .pipe(take(1));
  }

  private show(state, action, parentForm) {
    return of([])
      .pipe(switchMap(() => {
        action.data.dataObject = this.getArrayPath.transform(
          this.data.dataObject,
          action.path
        );

        const params = {
          maxWidth: '100vw',
          data: {
            title: action.dialogTitle || null,
            items: [action],
            parentForm,
            close: action.dialogClose
          }
        };

        this.alertDialog = this.dialog.open(SlotDialogComponent, params);
        const smallDialogSubscription = this.sizeObserver
          .pipe(takeUntil(this.destroyed)).subscribe(size => {
            if (size.breakpoints[Breakpoints.XSmall]) {
              this.alertDialog.updateSize('100vw', '100vh');
            } else if (
              size.breakpoints[Breakpoints.Small]
              && typeof action.dialogWidth !== 'undefined'
            ) {
              this.alertDialog.updateSize('80vw', '');
            } else {
              this.alertDialog.updateSize(action.dialogWidth || '80vw', '');
            }
          });
        this.alertDialog.afterClosed().subscribe(() => {
          smallDialogSubscription.unsubscribe();
        });
        this.session.setGlobalVar('modal', state);
        this.session.setGlobalVar('modalRef', this.alertDialog);
        return of([]);
      }));
  }

  // DEPRECATED use modifyFields
  private modifyPrices(state, action) {
    return of([])
      .pipe(switchMap(() => {
        const form = state.control.parent;
        const items = form.get('items');
        const itemIds = [];

        for (const item of items.controls) {
          if (item.controls.product_id !== ''
            && item.controls.product_id.value !== ''
          ) {
            itemIds.push(item.controls.product_id.value);
          }
        }

        if (itemIds.length > 0) {
          const params = {
            with: ['prices.pricelist'],
            mutations: [
              {
                constraint: 'whereIn',
                params: ['id', itemIds]
              }
            ]
          };
          const dataService = this.api.getService('ProductService');
          const prices = {};
          dataService.list(params)
            .pipe(take(1))
            .subscribe((response: JsonRpcResponse) => {
              response.result.data.forEach((product) => {
                let price;
                for (const productPrice of product.prices) {
                  if (productPrice.pricelist.code === state.value) {
                    price = productPrice.value;
                  }
                }
                prices[product.id] = price;
              });

              let orderTotalAmount = 0;
              let orderTotalDiscount = 0;
              for (const item of items.controls) {
                const priceItem = item.get('sell_price');
                const idItem = item.get('product_id').value;
                priceItem.setValue(prices[idItem]);

                // Total row price
                const total = priceItem.value * item.get('quantity').value;
                item.get('total').setValue(total.toFixed(2));

                // Total discount price
                const totalDiscount = priceItem.value * item.get('discount_quantity').value;
                item.get('discount_amount').setValue(totalDiscount.toFixed(2));

                orderTotalAmount += total;
                orderTotalDiscount += totalDiscount;
              }

              // Set order totals
              form.get('total_amount').setValue(orderTotalAmount.toFixed(2));
              form.get('total_discount').setValue(orderTotalDiscount.toFixed(2));
              const percent = (orderTotalDiscount / orderTotalAmount) * 100;
              form.get('amount_discount_percent').setValue(percent.toFixed(2));
            });
        }
        return of([]);
      }));
  }
  // DEPRECATED user modifyFields
  private setRowValues(state, action, parentForm) {
    return of([])
      .pipe(switchMap(() => {
        if (!state.control && action.global) {
          state.control = this.session.getGlobalVar(action.global).control;
        }
        if (state.control) {
          const group = state.control.parent;
          Object.keys(action).forEach((key) => {
            switch (action[key].type) {
              case 'control':
                let value;
                if (action[key].value) {
                  value = action[key].value;
                } else {
                  value = this.getArrayPath.transform(
                    state.dataObject,
                    action[key].valuePath
                  );
                }
                group.get(key).setValue(value);
                break;
              case 'price':
                const element = group.get(key);
                const parentGroup = state.control.parent;
                const parentArray = parentGroup.parent;
                const form = parentArray.parent;
                const relation = form.get(action[key].relation).value;
                if (
                  typeof state.dataObject !== 'undefined'
                  && typeof relation !== 'undefined'
                  && relation
                ) {
                  element.setValue(state.dataObject.prices[relation][0].value);
                }
                break;
              case 'calculate':
                let total = 0;
                let quantity = group.get('quantity').value;
                if (key === 'discount_amount') {
                  quantity = group.get('discount_quantity').value;
                }
                total = group.get('sell_price').value * quantity;
                group.get(key).setValue(total.toFixed(2));
                break;
              case 'calculate_total':
                const formArray = group.parent;
                let totalAmount = 0;
                for (const row of formArray.controls) {
                  totalAmount += Number(row.controls[action[key].field].value);
                }
                formArray.parent.get(key).setValue(totalAmount.toFixed(2));
                break;
              case 'calculate_discount_percent':
                const array = group.parent;
                let percent = 0;
                const orderTotalAmount = array.parent.get('total_amount').value;
                const orderTotalDiscount = array.parent.get('total_discount').value;
                percent = (orderTotalDiscount / orderTotalAmount) * 100;
                array.parent.get(key).setValue(percent.toFixed(2));
                break;
            }
          });
        }
        return of([]);
      }));
  }
  // DEPRECATED user modifyFields
  private modifyField(state, action) {
    return of([])
      .pipe(switchMap(() => {
        const global = this.session.getGlobalVar(action.global); // TODO: should be extracted in seperate object sessionStorage
        if (global.control) {
          // TODO. Should be changed with some path to the form
          const form = global.control.parent;
          const field = form.get(action.field);
          const value = this.getArrayPath.transform(
            state.dataObject,
            action.valuePath
          );

          switch (action.fieldType) {
            case 'autocomplete':
              const label = this.getArrayPath.transform(
                state.dataObject,
                action.labelPath
              );
              field.setValue({
                text: label,
                value
              });
              break;
          }
        }
        return of([]);
      }));
  }

  // generate all combination of multiple arrays
  private cartesian(...args) {
    const r = [];
    const max = args.length - 1;
    function helper(arr, i) {
      for (let j = 0, l = args[i].length; j < l; j++) {
        const a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max) {
          r.push(a);
        } else {
          helper(a, i + 1);
        }
      }
    }
    helper([], 0);
    return r;
  }
  private prepDataSourceParams(dataSourceParams, state, parentForm) {
    const params = {};
    for (const param in dataSourceParams) {
      if (
        typeof dataSourceParams[param] === 'string'
        && dataSourceParams[param].startsWith(':')
      ) {
        // if value is ':any' look for it in state
        const key = dataSourceParams[param].replace(':', '');
        params[param] = state[key];
        // TODO look for it in form???
      } else if (param === 'mutations') {
        const mutations = dataSourceParams[param];
        const newMutations = [];
        for (const mutation in mutations) {
          if (mutation) {
            const newMutation = {
              constraint: mutations[mutation].constraint,
              params: []
            };
            for (const mParam in mutations[mutation].params) {
              if (
                typeof mutations[mutation].params[mParam] === 'string'
                && mutations[mutation].params[mParam].startsWith(':')
              ) {
                // if value is ':any' look for it in state
                const key = mutations[mutation].params[mParam].replace(':', '');
                newMutation.params[mParam] = state[key];
                // TODO look for param in form???
              } else if (Array.isArray(mutations[mutation].params[mParam])) {
                // TODO look for param in the state
                // if value is an array, split each value by |
                const splits = [];
                for (const segment of mutations[mutation].params[mParam]) {
                  splits.push(segment.split('|'));
                }
                // generate all possible combinations of the splits of each segment
                const controlPaths = this.cartesian(...splits);
                // extract paths and combine the results
                const results = [];
                for (const controlPath of controlPaths) {
                  results.push(...this.getControlByPath.get(
                    parentForm,
                    controlPath
                  ));
                }
                newMutation.params[mParam] = results.filter((e) => e);
              } else {
                // handle static params
                newMutation.params[mParam] = mutations[mutation].params[mParam];
              }
            }
            newMutations.push(newMutation);
          }
        }
        params[param] = newMutations;
      } else {
        // handle static params
        params[param] = dataSourceParams[param];
      }
    }
    return params;
  }
  private evaluateExpression(expression, precision, scope, singleResult = false) {
    let componentMap = {};
    const components = expression.match(/{[\w/]+}/g);
    if (components) {
      for (const item of components) {
        const matched = item.replace(/[{}]/g, '').split('/');
        switch (matched[0]) {
          case 'self':
            // if scope of component is the current row
            expression = expression.replace(
              '{' + matched.join('/') + '}',
              parseFloat(scope.self.get(matched[1]).value)
            );
            break;
          case 'form':
            const placeholder = '{' + matched.join('/') + '}';
            matched.shift();
            let values = this.getControlByPath.get(scope.form, matched);
            componentMap[placeholder] = [];
            if (Array.isArray(values)) {
              for (let val of values) {
                if (isNaN(val) || val === '' || val == null) {
                  val = 0;
                }
                componentMap[placeholder].push(val);
              }
            } else {
              if (isNaN(values) || values === '' || values == null) {
                values = 0;
              }
              componentMap[placeholder].push(values);
            }
            break;
          case 'dataSource':
            // TODO if scope of component is the data source response
            break;
        }
      }
      let result;
      if (Object.keys(componentMap).length > 0) {
        if (singleResult) {
          const newComponentMap = {};
          for (const i in componentMap) {
            if (i) {
              newComponentMap[i] = [];
              newComponentMap[i].push(
                componentMap[i].reduce((total, val) => parseFloat(total) + parseFloat(val))
              );
            }
          }
          componentMap = newComponentMap;
        }
        result = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < componentMap[components[0]].length; i++) {
          let expr = expression;
          for (const placeholder of components) {
            expr = expr.replace(placeholder, componentMap[placeholder][i]);
          }
          // tslint:disable-next-line: no-eval
          result.push(parseFloat(eval(expr)).toFixed(precision));
        }
      } else {
        // tslint:disable-next-line: no-eval
        result = parseFloat(eval(expression)).toFixed(precision);
      }
      return result;
    }
  }
  private addRows(state, action, parentForm) {
    if (typeof action.dataSource !== 'undefined') {
      const dataService = this.api.getService(action.dataSource.service);
      const params = this.prepDataSourceParams(
        action.dataSource.params,
        state,
        parentForm
      );
      return dataService[action.dataSource.method](params)
        .pipe(take(1))
        .pipe(switchMap((response: JsonRpcResponse) => {
          if (response.result.data.length > 0) {
            let rowCounter = 0;
            const groupUid = Date.now();
            let validation: ValidationRule[];
            if (typeof action.conditions !== 'undefined') {
              const failedConditions = [];
              const messages = [];
              for (const condition of action.conditions) {
                let replaced = JSON.stringify(condition.path);
                const rootForm = this.parentForm.root as FormGroup;
                const formValues = rootForm.getRawValue();
                for (const p in formValues) {
                  if (typeof formValues[p] !== 'undefined') {
                    replaced = replaced.replace('{' + p + '}', formValues[p]);
                  }
                }
                if (replaced !== '') {
                  condition.path = JSON.parse(replaced);
                }
                if (!this.evaluator.exec(response.result, condition)) {
                  failedConditions.push(this.language.getLabel(condition.message));
                }
              }

              if (failedConditions.length > 0) {
                const config = new MatSnackBarConfig();
                config.horizontalPosition = 'right';
                config.verticalPosition = 'top';
                config.panelClass = ['alert', 'alert-danger'];
                config.data = {
                  messages: failedConditions
                };
                this.alert.openFromComponent(ToastComponent, config);
                return of([]);
              }
            }
            if (typeof action.validation !== 'undefined') {
              validation = this.getArrayPath.transform(
                response.result,
                action.validation.path
              );
            }
            for (const responseRow of response.result.data) {
              const firstRow = parentForm.get(action.target.formArray).at(0);
              if (typeof this.rowTemplate === 'undefined') {
                this.rowTemplate = firstRow;
              }
              let emptyControls = 0;
              // count how many controls have empty value
              for (const control in firstRow.controls) {
                if (
                  !firstRow.controls[control].value
                  || firstRow.controls[control].value === '0.00'
                  || firstRow.controls[control].value === '0'
                ) {
                  emptyControls++;
                }
              }
              if (emptyControls === Object.keys(firstRow.controls).length) {
                // if all first row controls have empty value, remove it
                parentForm.get(action.target.formArray).removeAt(0);
              }
              const newRow = this.cloner.clone(this.rowTemplate);
              // if adding more than one row add them each a group unique identifier
              newRow.groupUid = groupUid;
              if (rowCounter === 0) {
                newRow.startGroupClass = 'groupStart';
              }
              newRow.reset();

              for (const control in action.target.map) {
                if (newRow.get(control)) {
                  let value;
                  const definition = action.target.map[control];
                  if (Array.isArray(definition)) {
                    // if this control is directly mapped to response property, get it
                    value = this.getArrayPath.transform(responseRow, definition);
                  } else {
                    if (typeof definition.path !== 'undefined') {
                      // if this control is directly mapped to a response property, but
                      // it contains a map of values that vary by other control's value
                      // get the map
                      const valueList = this.getArrayPath.transform(
                        responseRow,
                        definition.path
                      );

                      // get the value of the other control and extract the proper value
                      if (typeof definition.variesByControl !== 'undefined') {
                        const variesByValue = parentForm.get(definition.variesByControl).value;
                        if (typeof variesByValue === 'object') {
                          value = valueList[variesByValue.value];
                        } else if (variesByValue) {
                          value = valueList[variesByValue];
                        } else {
                          value = definition.default;
                        }
                      }
                    }
                    if (typeof definition.expression !== 'undefined') {
                      // if the control value is a math expression
                      // extract it's components
                      value = this.evaluateExpression(
                        definition.expression,
                        definition.precision,
                        {
                          self: newRow,
                          form: parentForm,
                          dataSource: response.result.data
                        }
                      );
                    }
                  }
                  newRow
                    .get(control)
                    .setValue(value);
                }
              }
              if (
                typeof validation !== 'undefined'
              ) {
                for (const validator of validation) {
                  const item = newRow.get(action.validation.map.items).value;
                  for (let property of validator.change) {
                    if (validator.items.indexOf(item) >= 0) {
                      if (typeof action.validation.map[property] !== 'undefined') {
                        property = action.validation.map[property];
                      }
                      newRow.get(property).enable({ onlySelf: true });
                    }
                  }
                  for (const property in validator.check) {
                    if (validator.items.indexOf(item) >= 0) {
                      let mappedProperty;
                      if (typeof action.validation.map[property] !== 'undefined') {
                        mappedProperty = action.validation.map[property];
                      } else {
                        mappedProperty = property;
                      }
                      const validationFilter: FormArrayValidationFilter = {
                        controlName: action.validation.map.items,
                        controlValues: validator.items,
                      };
                      newRow.get(mappedProperty).addValidators([
                        CustomValidators['formArray' + validator.rule](
                          mappedProperty,
                          validator.check[property],
                          validationFilter,
                          validator.deviation
                        )
                      ]);
                    }
                  }
                }
              }
              parentForm
                .get(action.target.formArray)
                .push(newRow);
              rowCounter++;
            }
          }

          parentForm.get(this.data.name).reset();
          return of([]);
        }));

    } else {
      return of([])
        .pipe(switchMap(() => {
          let prevRow;
          if (action.prepend) {
            prevRow = parentForm.parent.at(0);
          } else {
            prevRow = parentForm.parent.at(parentForm.parent.length - 1);
          }
          let emptyControls = 0;
          // count how many controls have empty value
          for (const control in prevRow.controls) {
            if (
              !prevRow.controls[control].value
              || prevRow.controls[control].value === '0.00'
              || prevRow.controls[control].value === '0'
            ) {
              emptyControls++;
            }
          }
          if (emptyControls === Object.keys(prevRow.controls).length) {
            // if all last row controls have empty value, don't add new row
            return of([]);
          }
          if (typeof this.rowTemplate === 'undefined') {
            this.rowTemplate = prevRow;
          }
          const newRow = this.cloner.clone(this.rowTemplate);
          newRow.reset();
          for (let i = 0; i < action.count; i++) {
            parentForm.parent.controls.map((item: FormGroup) => {
              item.get('add').disable();
            });
            if (action.prepend) {
              parentForm.parent.insert(0, newRow);
            } else {
              parentForm.parent.push(newRow);
            }
          }
          return of([]);
        }));
    }
  }

  private prepSourcePath(path, form: AbstractControl, state) {
    const newPath = JSON.parse(JSON.stringify(path));
    for (const segment in newPath) {
      if (
        typeof newPath[segment] === 'string'
        && newPath[segment].startsWith(':')
      ) {
        // if value is ':any' look for it in state
        const key = newPath[segment].replace(':', '');
        newPath[segment] = state[key];
      }
    }
    const result = [];
    for (const segment in newPath) {
      if (Array.isArray(newPath[segment])) {
        // if path segment is another path
        // get its value
        const segmentValue = this.getControlByPath.get(
          form,
          newPath[segment]
        );
        if (Array.isArray(segmentValue)) {
          // if multiple path with each value replacing the segment
          for (const value of segmentValue) {
            const resultPath = JSON.parse(JSON.stringify(newPath));
            resultPath[segment] = value;
            result.push(resultPath);
          }
        } else {
          // if single value return path with the segment
          // replaced by the value
          const resultPath = JSON.parse(JSON.stringify(newPath));
          resultPath[segment] = segmentValue;
          result.push(resultPath);
        }
        break;
      }
    }
    if (result.length > 0) {
      return result;
    }
    return [path];
  }
  private modifyDataSourceIndependantField(state, map, parentForm) {
    let target;
    switch (map.type) {
      case 'calculate':
        target = this.getControlByPath.get(parentForm, map.target, false);
        const value = this.evaluateExpression(
          map.expression,
          map.precision,
          {
            form: parentForm
          },
          (target instanceof AbstractControl)
        );
        if (Array.isArray(value)) {
          for (const i in value) {
            if (target instanceof AbstractControl) {
              target.setValue(value[i]);
              break;
            } else {
              target[i].setValue(value[i]);
            }
          }
        } else {
          target.setValue(value);
        }
        break;
      case 'set':
        target = this.getControlByPath.get(parentForm, map.target, false);
        if (typeof map.path === 'undefined' && state.value) {
          target.setValue(state.value);
        } else {
          target.setValue(this.getArrayPath.transform(state.dataObject, map.path));
        }
        break;
    }
  }
  private modifyFields(state, action, parentForm) {
    if (typeof action.dataSource !== 'undefined') {
      const dataService = this.api.getService(action.dataSource.service);
      const params = this.prepDataSourceParams(
        action.dataSource.params,
        state,
        parentForm
      );
      return dataService[action.dataSource.method](params)
        .pipe(take(1))
        .pipe(switchMap((response: JsonRpcResponse) => {
          for (const map of action.map) {
            if (map.type === 'setFromDataSource') {
              const source = this.prepSourcePath(map.source, parentForm, state);
              const target = this.getControlByPath.get(parentForm, map.target, false);
              if (Array.isArray(source)) {
                // if we have multiple sources (like when source is located in FormArray)
                // loop through them and set each value
                // IMPORTANT: target must also select the same
                // FormArray the source is based on
                // tslint:disable-next-line: forin
                for (const i in source) {
                  const value = this.getArrayPath.transform(
                    response.result.data,
                    source[i]
                  );
                  if (typeof value !== 'undefined') {
                    target[i].setValue(value[0]);
                  }
                }
              }
            }
            this.modifyDataSourceIndependantField(state, map, parentForm);
          }
          return of([]);
        }));
    } else {
      return of([])
        .pipe(switchMap(() => {
          for (const map of action.map) {
            this.modifyDataSourceIndependantField(state, map, parentForm);
          }
          return of([]);
        }));
    }
  }

  private removeRow(state, action, parentForm) {
    return of([])
      .pipe(switchMap(() => {
        const formGroup = state.control.parent;
        const formArray = formGroup.parent;
        const rowsToRemove = [];

        if (typeof this.rowTemplate === 'undefined') {
          const firstRow = formArray.at(0);
          this.rowTemplate = firstRow;
        }

        for (const idx in formArray.controls) {
          if (typeof formGroup.groupUid === 'undefined') {
            if (formArray.controls[idx] === formGroup) {
              rowsToRemove.push(idx);
            }
          } else {
            if (formArray.controls[idx].groupUid === formGroup.groupUid) {
              rowsToRemove.push(idx);
            }
          }
        }
        for (const idx of rowsToRemove.reverse()) {
          formArray.removeAt(idx);
        }
        if (formArray.controls.length === 0) {
          const newEmptyRow: FormGroup = this.cloner.clone(this.rowTemplate);
          // tslint:disable-next-line: forin
          newEmptyRow.reset();
          formArray.push(newEmptyRow);
        }
        let addControl;
        if (action.reverse) {
          addControl = formArray.controls[0].get('add');
        } else {
          addControl = formArray.controls[formArray.controls.length - 1].get('add');
        }
        if (addControl) {
          addControl.enable();
        }
        return of([]);
      }));
  }

  private validate(state, action, parentForm) {
    return of([])
      .pipe(switchMap(() => {
        const target = this.getControlByPath.get(parentForm, action.target, false);
        target.markAsTouched();
        return of([]);
      }));
  }

  private closeModal(state, action) {
    return of([])
      .pipe(switchMap(() => {
        this.session.getGlobalVar('modalRef').close();
        return of([]);
      }));
  }

  private enableFields(state, action, parentForm) {
    return of([])
      .pipe(switchMap(() => {
        for (const controlPath of action.fields) {
          const control: AbstractControl | AbstractControl[] = this.getControlByPath.get(
            parentForm,
            controlPath,
            false
          );
          if (Array.isArray(control)) {
            for (const c of control) {
              c.enable();
            }
          } else {
            control.enable();
          }
        }
        return of([]);
      }));
  }

  // TODO decide if we should make seperate action component for
  // each different component or group them some how

  // tree list actions
  private addNode(state, action) {
    return of([])
      .pipe(switchMap(() => {
        if (typeof this.parentComponent !== 'undefined') {
          this.parentComponent.addNode(
            this.data.node,
            action.propsToKeep,
            action.addChild
          );
        }
        return of([]);
      }));
  }

  private removeNode(state, action) {
    return of([])
      .pipe(switchMap(() => {
        if (typeof this.parentComponent !== 'undefined') {
          this.parentComponent.removeNode(this.data.node);
        }
        return of([]);
      }));
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
