<ng-container *ngIf="data" class="row" [class]="data.rowClass">
  <div [class]="data.gridColClass" [routerLink]="data.dataObject | link: data.link">
    <mat-card>
      <app-slot
        *ngIf="data.image"
        [items]="[data.image]"
        [componentValue]="data.dataObject | getArrayPath: data.image.path"
      ></app-slot>
      <mat-card-content *ngIf="data.content">
        <app-slot
          [items]="data.content"
          [componentValue]="data.dataObject | getArrayPath: data.content.path"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
      </mat-card-content>
      <mat-card-actions *ngIf="data.actions">
        <app-slot
          [items]="data.actions"
          [componentValue]="data.dataObject | getArrayPath: data.actions.path"
          style="display: block; width: 100%; height: 100%"
        ></app-slot>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-container>
