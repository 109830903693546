import { NgModule, ErrorHandler, APP_INITIALIZER, CompilerFactory, COMPILER_OPTIONS, Compiler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { VexSdkModule } from 'ng-vex-sdk';
import { AppRoutingModule } from './app-routing.module';
import { RoutingService } from './backbone/routing.service';
import { AppComponent } from './app.component';
import { ErrorService } from './backbone/error.service';
import { AlertDialogModule } from './components/alert-dialog/alert-dialog.module';
import { ToastModule } from './components/toast/toast.module';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { PrimitiveModule } from './components/primitive/primitive.module';
import { LanguageService } from './backbone/language.service';
import { PermissionsService } from './backbone/permissions.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './backbone/interceptors/loader.interceptor';
import { LoaderModule } from './components/loader/loader.module';
import { SlotDialogModule } from './components/slot-dialog/slot-dialog.module';
import { QueryPipesModule } from './backbone/pipes/query-pipes.module';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './backbone/date-adapter';
import { LayoutsModule } from './components/layouts/layouts.module';
import LocaleBG from '@angular/common/locales/bg';

registerLocaleData(LocaleBG);

export function initApp(
   routing: RoutingService,
   permissions: PermissionsService,
   language: LanguageService,
) {
   return (): Promise<any> => {
      return language.getLanguages().then(() => {
         return permissions.checkSession().then(() => {
            return routing.generateRoutes();
         });
      });
   };
}

export function createCompiler(compilerFactory: CompilerFactory) {
   return compilerFactory.createCompiler();
}
@NgModule({
   declarations: [
      AppComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      CommonModule,
      VexSdkModule,
      AppRoutingModule,
      PrimitiveModule,
      LayoutsModule,
      AlertDialogModule,
      SlotDialogModule,
      LoaderModule,
      ToastModule,
      QueryPipesModule
   ],
   providers: [
      RoutingService,
      LanguageService,
      PermissionsService,
      CurrencyPipe,
      DecimalPipe,
      {
         provide: APP_INITIALIZER,
         useFactory: initApp,
         deps: [RoutingService, PermissionsService, LanguageService],
         multi: true
      },
      {
         provide: COMPILER_OPTIONS,
         useValue: {},
         multi: true
      },
      {
         provide: CompilerFactory,
         useClass: JitCompilerFactory,
         deps: [COMPILER_OPTIONS]
      },
      {
         provide: Compiler,
         useFactory: createCompiler,
         deps: [CompilerFactory]
      },
      {
         provide: ErrorHandler,
         useClass: ErrorService
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: LoaderInterceptor,
         multi: true
      },
      {
         provide: DateAdapter,
         useClass: CustomDateAdapter
      },
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
