import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LanguageService } from 'src/app/backbone/language.service';

interface UploadedFile extends File {
  link?: string;
  remoteFilename?: string;
}

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements OnInit {
  files: UploadedFile[] = [];

  @Input() public data: any = {};
  @Input() public parentForm: FormGroup;

  constructor(public language: LanguageService) { }

  ngOnInit(): void {
  }

  onSelect(event) {
    if (this.data.multiple) {
      this.files.push(...event.addedFiles);
    } else {
      this.files = event.addedFiles;
    }
    let processed = event.addedFiles.length;
    this.data.value = (this.data.multiple) ? [] : {};
    if (this.data.change) {
      this.data.changeParams = {};
      for (const file of event.addedFiles) {
        this.readFile(file).then(fileContents => {
          if (typeof this.data.changeParams.value === 'undefined') {
            this.data.changeParams.value = (this.data.multiple) ? [] : {};
          }
          if (this.data.multiple) {
            this.data.value.push({
              filename: file.name,
              content: fileContents
            });
            this.data.changeParams.value.push({
              filename: file.name,
              content: fileContents
            });
          } else {
            this.data.value = {
              filename: file.name,
              content: fileContents
            };
            this.data.changeParams.value = {
              filename: file.name,
              content: fileContents
            };
          }

          this.data.changeParams.event = 'change';
          processed--;
        }).then(() => {
          if (processed === 0) {
            this.data.change(this.data.changeParams)
              .subscribe((response) => {
                if (typeof response.result === 'undefined') {
                  this.files = [];
                } else {
                  // tslint:disable-next-line: forin
                  for (const i in response.result.uploaded) {
                    const uploadedFile = this.files.filter((item) => {
                      return item.name === i && typeof item.remoteFilename === 'undefined';
                    });
                    uploadedFile[0].remoteFilename = response.result.uploaded[i].filename;
                    uploadedFile[0].link = response.result.uploaded[i].url;
                  }
                }
              });
          }
        });
      }
    } else if (this.parentForm instanceof FormGroup) {
      for (const file of event.addedFiles) {
        this.readFile(file).then(fileContents => {
          if (this.data.multiple) {
            this.data.value.push({
              filename: file.name,
              content: fileContents
            });
          } else {
            this.data.value = {
              filename: file.name,
              content: fileContents
            };
          }
          processed--;
        }).then(() => {
          if (processed === 0) {
            this.parentForm.get(this.data.name).setValue(this.data.value);
          }
        });
      }
    }
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  click(event, file) {
    event.stopPropagation();
    window.open(file.link, '_blank');
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };

      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }
}
