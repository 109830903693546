import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { SlotItem } from 'src/app/components/slot/slot-item';

interface Slots {
  header: SlotItem[];
  menu: SlotItem[];
  main: SlotItem[];
  footer: SlotItem[];
}

@Component({
  selector: 'app-centered-header-main-footer',
  templateUrl: './centered-header-main-footer.component.html',
  styleUrls: ['./centered-header-main-footer.component.scss']
})
export class CenteredHeaderMainFooterComponent implements OnInit {
  public slots: Slots;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe(data => {
      this.slots = data.slots;
    });
  }

}
