import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/backbone/language.service';

@Component({
  selector: 'app-slot-dialog',
  templateUrl: './slot-dialog.component.html',
  styleUrls: ['./slot-dialog.component.scss']
})
export class SlotDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public language: LanguageService
  ) { }

  ngOnInit() {
    if (typeof this.data.close === 'undefined') {
      this.data.close = true;
    }
  }
}
