<mat-form-field appearance="fill" *ngIf="parentForm" [formGroup]="parentForm">
  <mat-label>{{ language.getLabel(data.label) }}</mat-label>
  <mat-select
    [id]="data.id ? data.id : null"
    [(value)]="data.selected"
    [formControlName]="data.name"
    [multiple]="data.multiple"
    [placeholder]="language.getLabel(data.pleaceholder) ? data.selected : null"
    [required]="data.required"
    (selectionChange)="change($event, options)"
    [disabled]="data.disabled"
  >
    <mat-option *ngIf="data.searchable">
      <ngx-mat-select-search
        [formControl]="searchOptionsCtrl"
        [placeholderLabel]="language.getLabel(data.searchPlaceholder)"
        [noEntriesFoundLabel]="language.getLabel(data.searchNotFound)"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="data.emptyOption" value="">
      {{ language.getLabel(data.emptyOption) }}
    </mat-option>
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option.value"
    >
      {{ language.getLabel(option.text) }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="fill" *ngIf="!parentForm">
  <mat-label>{{ language.getLabel(data.label) }}</mat-label>
  <mat-select
    [id]="data.id ? data.id : null"
    [(value)]="data.selected"
    [multiple]="data.multiple"
    [placeholder]="language.getLabel(data.pleaceholder) ? data.selected : null"
    [required]="data.required"
    (selectionChange)="change($event, options)"
  >
    <mat-option *ngIf="data.searchable">
      <ngx-mat-select-search
        [formControl]="data.name + '-filter'"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="data.emptyOption" value="">
      {{ language.getLabel(data.emptyOption) }}
    </mat-option>
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option.value"
    >
      {{ language.getLabel(option.text) }}
    </mat-option>
  </mat-select>
</mat-form-field>
