<div *ngIf="parentForm" [formGroup]="parentForm" [class]="data.class">
  <mat-checkbox
    [color]="data.color"
    [(indeterminate)]="data.indeterminate"
    [labelPosition]="data.labelPosition"
    [disabled]="data.disabled"
    [formControlName]="data.name"
    (change)="change()"
  >
    {{ language.getLabel(data.label) }}
  </mat-checkbox>
</div>
<ng-container *ngIf="!parentForm">
  <mat-checkbox
    [color]="data.color"
    [(ngModel)]="data.checked"
    [(indeterminate)]="data.indeterminate"
    [labelPosition]="data.labelPosition"
    [disabled]="data.disabled"
    (change)="change()"
  >
    {{ language.getLabel(data.label) }}
  </mat-checkbox>
</ng-container>
