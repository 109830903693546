import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { JsonRpcResponse } from 'ng-vex-sdk';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/backbone/api.service';
import { LanguageService } from 'src/app/backbone/language.service';
import { GetArrayPathPipe } from 'src/app/backbone/pipes/get-array-path.pipe';
import { ISlotComponent } from 'src/app/components/slot/slot-component';

interface Item {
  text: string;
  value: number;
}

@Component({
  selector: 'app-multi-autocomplete',
  templateUrl: './multi-autocomplete.component.html',
  styleUrls: ['./multi-autocomplete.component.scss']
})
export class MultiAutocompleteComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any;
  @Input() public parentForm: FormGroup;
  public separatorKeysCodes: number[] = [COMMA];
  public formControl;
  public responseData;
  public filteredItems: Array<{ value: any, text: string }> = [];
  public items: Array<Item> = [];

  private lastSearch;
  private subsc: Subscription[] = [];

  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;

  constructor(
    private api: ApiService,
    private getArrayPath: GetArrayPathPipe,
    public language: LanguageService,
  ) { }

  ngOnInit(): void {
    if (typeof this.data.options === 'undefined') {
      if (this.data.load) {
        this.load();
      }
      if (this.parentForm) {
        this.formControl = this.parentForm.controls[this.data.name];
      } else {
        this.formControl = new FormControl();
      }

      const subsc = this.formControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(selectedValue => {
          if (typeof selectedValue === 'object') {
            if (Array.isArray(selectedValue)) {
              for (const value of selectedValue) {
                this.items.push(value);
              }
            } else if (selectedValue !== null) {
              this.items.push(selectedValue);
            }
          } else {
            if (selectedValue === this.lastSearch) {
              return;
            }
            const search = '%' + selectedValue + '%';
            if (selectedValue !== ''
              && typeof selectedValue === 'string'
              && selectedValue.length > 1
            ) {
              this.load(search);
            }
          }
        });
      this.subsc.push(subsc);

      if (typeof this.formControl.value === 'object' && this.formControl.value !== null) {
        // If value comes from response or is set outside of the component
        if (!Array.isArray(this.formControl.value)) {
          this.formControl.value = [this.formControl.value];
        }
        for (const item of this.formControl.value) {
          this.items.push(item);
        }
      } else {
        const val = this.data.value;
        if (val) {
          this.formControl.setValue(val);
        }
      }

    }
  }

  private load(search?: string) {
    const dataService = this.api.getService(this.data.dataSource.service);
    let params = {
      mutations: []
    };
    if (
      typeof this.data.dataSource.params === 'object'
      && Object.keys(this.data.dataSource.params).length > 0
    ) {
      params = JSON.parse(JSON.stringify({ ...this.data.dataSource.params }));
    }
    if (typeof search !== 'undefined') {
      if (typeof this.data.termKey === 'undefined') {
        this.data.termKey = 'name';
      }
      const mutation = {
        constraint: 'whereTranslation',
        params: [this.data.termKey, 'like', search]
      };
      params.mutations.push(mutation);
    }

    dataService[this.data.dataSource.method](params)
      .pipe(take(1))
      .subscribe((response: JsonRpcResponse) => {
        this.filteredItems = [];
        this.responseData = response.result.data;

        response.result.data.forEach((item) => {
          const value = this.getArrayPath.transform(
            item,
            this.data.dataSource.valuePath
          );
          let label = '';
          if (this.data.dataSource.multiLabelPath) {
            for (const path of this.data.dataSource.multiLabelPath) {
              const lab = this.getArrayPath.transform(
                item,
                path
              );
              if (lab) {
                label += lab + ' | ';
              }
            }
          } else {
            label = this.getArrayPath.transform(
              item,
              this.data.dataSource.labelPath
            );
          }
          this.filteredItems.push({
            value,
            text: label
          });
        });
      });
  }

  private doChange(event?) {
    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {
          value: this.items.map(i => i.value)
        };
      } else {
        this.data.changeParams.value = this.items.map(i => i.value);
      }

      this.data.changeParams.event = 'change';
      if (this.formControl) {
        this.data.changeParams.control = this.formControl;
      }

      if (this.responseData && event) {
        this.responseData.forEach((item) => {
          if (item[this.data.dataSource.valuePath] === event.option.value) {
            this.data.changeParams.dataObject = item;
          }
        });
      }

      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        const subsc = result.subscribe();
        this.subsc.push(subsc);
      }
    }
  }
  change(event: MatAutocompleteSelectedEvent) {
    this.items.push({
      text: event.option.viewValue,
      value: event.option.value
    });
    this.itemInput.nativeElement.value = '';

    this.doChange(event);
    this.formControl.setValue(null);
  }

  remove(itemToRemove: Item): void {
    const index = this.items.indexOf(itemToRemove);

    if (index >= 0) {
      this.items.splice(index, 1);
    }

    this.doChange();
  }

  ngOnDestroy(): void {
    this.subsc.forEach(entry => {
      entry.unsubscribe();
    });
  }
}
