import { Injectable, Injector } from '@angular/core';
import { ApiService } from 'src/app/backbone/api.service';
import { JsonRpcResponse } from 'ng-vex-sdk/public-api';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
   public permissions: [];
   public roles: any;
   public superAdmin: boolean;

  constructor(
      private injector: Injector,
      private api: ApiService
  ) {}

  setPermissions(permissions: any) {
      this.permissions = permissions;
  }

  setRoles(roles: Array<object>) {
    this.roles = roles;
  }

  setSession(session: any) {
    this.setRoles(session.user.roles);
    this.setPermissions(session.permissions);
    this.isSuperAdmin();
  }

  checkSession() {
    return new Promise<void>((resolve, reject) => {
      const userService = this.api.getService('UserService');
      userService.checkSession()
      .subscribe((response: JsonRpcResponse) => {
        this.setSession(response.result);
        resolve();
      });
    });
  }

  checkpermissions(object: any) {
    let result = true;
    if (!this.isSuperAdmin()
      && typeof object.requiredPermissions !== 'undefined'
    ) {
      object.requiredPermissions.forEach(element => {
        if (typeof this.getPermissions(element) === 'undefined') {
          result = false;
        }
      });
    }
    return result;
  }

  getPermissions(key?: string) {
    if (typeof key !== 'undefined' && key !== '') {
        return this.permissions[key];
    }
    return this.permissions;
  }

  isSuperAdmin() {
    this.superAdmin = false;
    this.roles.forEach(role => {
      if (role.key === 'super_admin') {
        this.superAdmin = true;
      }
    });
    return this.superAdmin;
  }
}
