import { Component, OnInit, Input } from '@angular/core';
import { ISlotComponent } from 'src/app/components/slot/slot-component';
import { LanguageService } from 'src/app/backbone/language.service';
import { GetArrayPathPipe } from 'src/app/backbone/pipes/get-array-path.pipe';
import { TransformService } from 'src/app/backbone/transform.service';

@Component({
    selector: 'app-chart-bar-horizontal',
    templateUrl: './chart-bar-horizontal.component.html',
    styleUrls: ['./chart-bar-horizontal.component.scss'],
})
export class ChartBarHorizontalComponent implements OnInit, ISlotComponent {
    @Input() public data: any = {};

    constructor(
        private language: LanguageService,
        private getArrayPath: GetArrayPathPipe,
        private transformService: TransformService
    ) { }

    ngOnInit() {
        if (typeof this.data.bars !== 'undefined') {
            const barsArray = [];

            // tslint:disable-next-line: forin
            for (const name in this.data.bars) {
                let labelValue = this.getArrayPath.transform(this.data.value, this.data.bars[name]);
                if (typeof this.data.transform !== 'undefined') {
                    labelValue = this.transformService.transform(
                        this.getArrayPath.transform(this.data.value, this.data.bars[name]),
                        this.data.transform
                    );
                }
                barsArray.push({
                    name: this.language.getLabel(name) + ' (' + labelValue + ')',
                    value: this.getArrayPath.transform(this.data.value, this.data.bars[name])
                });
            }
            this.data.value = barsArray;
        }
    }

    onSelect(data): void {
        //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    }

    onActivate(data): void {
        //console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data): void {
        //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }
}